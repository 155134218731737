.quality-item__circle{
    position: relative;
    border-radius: 50%;
    width: calc(210rem/16);
    height: calc(210rem/16);
    padding: calc(16rem/16) calc(18rem/16);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    line-height: calc(22rem/16);

    background-image: url("/static/img/circle-green.svg");
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 768px){
        width: calc(240rem/16);
        height: calc(240rem/16);
        padding: calc(24rem/16);
        font-size: calc(18rem/16);
        line-height: calc(26rem/16);
        justify-content: flex-start;
    }
}

.quality-item__title{
    font-size: calc(20rem/16);
    color: var(--color-primary);
    margin-bottom: calc(8rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
        margin-bottom: calc(10rem/16);
    }
}

.quality-item__icon{
    font-size: calc(37rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(55rem/16);
        margin-bottom: calc(20rem/16);
    }
}

.quality-slide .swiper-container{
    overflow: visible;
}
.title-block{
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(30rem/16);
    }
}


.title-block__subtitle{
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    text-transform: uppercase;
    letter-spacing: calc(1rem/16);
    color: var(--color-secondary);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.title-block--main .title-block__title{
    font-size: calc(55rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(100rem/16);
    }
}

.main-content--padding-top .title-block--main {
    padding-top: 0;
}

.title-block--acco{
    margin-top: calc(-12rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(-30rem/16);
    }
}

.title-block--acco .title-block__title{
    font-size: calc(60rem/16);
    text-align: center;
    @media screen and (min-width: 768px){
       font-size: calc(80rem/16);
    }
}
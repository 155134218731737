.flag{
    width: 20px;
    height: 17px;
    display: inline-block;
    background-image: url(/static/build/img/flag-sprite.png);
    background-position: 143px 61px;
    vertical-align: calc(-2rem/16);
    margin-right: calc(3rem/16);
}

.flag-oe{
    background-position: 143px 61px;

}

.flag-sbg{
    background-position: 115px 61px;

}

.flag-ooe{
    background-position: 87px 61px;
}

.flag-stm{
    background-position: 58px 61px;
}

.flag-noe{
    background-position: 30px 61px;
}

.flag-ktn{
    background-position: 143px 31px;
}

.flag-tir{
    background-position: 114px 31px;
}

.flag-bgl{
    background-position: 87px 31px;
}

.flag-vbg{
    background-position: 57px 31px;
}


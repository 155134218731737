.catalog-teaser{
    position: relative;
    @media screen and (max-width: 767px){
       text-align: center;
    }
}
.catalog-teaser__links>li:hover{
    color: var(--color-primary);
}


.catalog-teaser__links__icon{
    margin-top: 5px;
}

.catalog-teaser__links{
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    
    @media screen and (max-width: 767px){
       text-align: left;
    }
}

.catalog-teaser__body{
    padding: calc(20rem/16);
}

.catalog-teaser__title{
    line-height: calc(29/22);
}

/* enquiry catalog teaser */

.catalog-teaser--enquiry .btn-remove{
    background-color: var(--color-danger);
    color: #fff;
    width: calc(24rem/16);
    height: calc(24rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: calc(-10rem/16);
    top: calc(-10rem/16);
    padding: 0;
    border-radius: 50%;
    font-size: calc(12rem/16);
}

.catalog-teaser--enquiry .btn-remove .icon{
    transform: rotate(45deg);
}

.catalog-teaser--enquiry{
    background-color: #fff;
    @media screen and (min-width: 768px){
       width: 80%;
    }
}

.catalog-teaser--enquiry .catalog-teaser__body{
    padding: calc(15rem/16);
}

.catalog-teaser--enquiry .catalog-teaser__title{
    font-size: calc(20rem/16);
}
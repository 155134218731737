.region-map__item,
.region-map__item-map{
    cursor: pointer;
}

.region-map__item path,
path.region-map__item-map,
polygon.region-map__item-map{
    fill: #fff!important;
}

.region-map__item:not([data-href="#"]):hover path,
.interactive-region-map__map path.region-map__item-map.is-active,
.interactive-region-map__map path.region-map__item-map:hover,
.interactive-region-map__map polygon.region-map__item-map:hover,
.interactive-region-map__map polygon.region-map__item-map.is-active{
    fill: var(--color-primary)!important;
}
.region-map__item[data-href="#"] path,
.region-map__item[data-href="#"] text {
    cursor: auto;
}

.region-map__item text,
.region-map__item-text{
    font-family: var(--font-default-bold)!important;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-size: 11px!important;
}

.region-map__item:not([data-href="#"]):hover text,
.interactive-region-map__map path.region-map__item-map:hover text,
.interactive-region-map__map polygon.region-map__item-map:hover text,
.interactive-region-map__map polygon.region-map__item-map.is-active text{
    fill: #fff!important;
}

.region-map-area__map svg{
    @media screen and (min-width: 768px){
        margin-left: calc(-100rem/16);
    }
    
    @media screen and (max-width: 767px){
        width: calc(320rem/16);
        height: auto;
        margin-left: calc(-50rem/16);
    }
}


/* Interactive Region Map */

.interactive-region-map__item{
    @media screen and (min-width: 768px){
        position: absolute;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 250ms ease;
        visibility: hidden;
        width: 100%;
        top: calc(-60rem/16);
    }

}

.interactive-region-map__item.is-active{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
    }

}

.interactive-region-map__text-item{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
    cursor: pointer;
    padding-bottom: calc(8rem/16);
    position: relative;
    display: inline-block;
    line-height: 1.25;
    @media screen and (min-width: 768px){
        font-size: calc(35rem/16);
        line-height: 1.3;
    }
}

.is-active .interactive-region-map__text-item{
    color: var(--color-primary);
}

.interactive-region-map__link{
    display: none;
    @media (hover: none) and (pointer:coarse) and (min-width: 768px) {
        display: block;
        margin-top: calc(10rem/16);
    }
}

/*.interactive-region-map__text-item:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: calc(60rem/16);
    height: calc(4rem/16);
    background-color: var(--color-primary);
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    transform-origin: center;
    transition: all 250ms ease;
}

.is-active .interactive-region-map__text-item:before {
    transform: translateX(-50%) scaleX(1);
}*/

.interactive-region-map__subtext {
    font-size: calc(16rem/16);
    line-height: 1.2;
    margin-top: calc(5rem/16);
    font-family: var(--font-default);
    display: none;

    @media screen and (min-width: 1200px){
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 768px){
        display: block;
        font-size: calc(18rem/16);
    }
/*
    @media (hover: none) and (pointer:coarse) {
        display: none;
    }*/

}

.interactive-region-map__map svg{
    max-width: 100%;
    height: auto;
    overflow: visible;
}

.navbar-subnav--map .interactive-region-map-area .container {
    @media screen and (max-width: 767px) {
        padding: 0;
    }

}

.navbar-subnav--map .interactive-region-map{
    @media screen and (max-width: 767px){
        margin-top: 0!important;
    }
}

.navbar-subnav--map .interactive-region-map__map svg{
    @media screen and (min-width: 768px) and (min-height: 721px){
       height: 48vh;
    }
    @media screen and (min-width: 768px) and (max-height: 720px){
        height: 42vh;
    }
}
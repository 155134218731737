.location-map__body{
    background-color: #fff;
    padding: calc(25rem/16);
    
    @media screen and (min-width: 1200px){
        padding: calc(50rem/16) calc(60rem/16);
    }
    
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.location-map-legend-item__label{
    text-transform: uppercase;
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
}

.location-map-legend>li:not(:last-child){
    margin-bottom: calc(16rem/16);
}

.location-map-legend-item__icon{
    width: calc(30rem/16);
    height: calc(36rem/16);
    background-image: url('/static/img/map/marker-direct.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: calc(10rem/16);
}

.location-map-legend-item__icon.legend-icon-direct{
    background-image: url('/static/img/map/marker-direct.svg');
}

.location-map-legend-item__icon.legend-icon-shop{
    background-image: url('/static/img/map/marker-shop.svg');
}

.location-map-legend-item__icon.legend-icon-market{
    background-image: url('/static/img/map/marker-market.svg');
}

.location-map-legend-item__icon.legend-icon-bar{
    background-image: url('/static/img/map/marker-bar.svg');
}

.location-map-legend-item__icon.legend-icon-crown{
    background-image: url('/static/img/map/crown.svg');
    height: calc(17rem/16);
    margin-top: calc(7rem/16);
}

.location-map-legend-item__icon.legend-icon-bio{
    background-image: url('/static/img/map/bio.svg');
    height: calc(16rem/16);
    margin-top: calc(7rem/16);
}


.location-map__map{
    background: var(--color-bg);
}
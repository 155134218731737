.lightbox-img__btn{
    position: absolute;
    bottom: calc(30rem/16);
    right: calc(30rem/16);
    color: #fff;
    z-index: 1;
    background-color: transparent;
    border: none;
}

.lightbox-img__btn{
    position: absolute;
    right: calc(-18rem/16);
    bottom: calc(-18rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid #fff;
    border-radius: 50%;
    content: '';
    opacity: 0;
    transform: scale3d(0.65,0.65,1);
    transform-origin: bottom right;
    transition: opacity 0.35s, transform 0.35s;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 0;
    color: #fff;
    z-index: 2;
}

.lightbox-img__btn .icon{
    position: relative;
    top: calc(-6rem/16);
    left: calc(-5rem/16);
    font-size: calc(12rem/16);
}


.lightbox-img:hover .lightbox-img__btn {
    opacity: 1;
    transform: scale3d(1,1,1);
}

.lightbox-img__dynamic-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: calc(20rem/16);
    line-height: calc(30/24);
    font-family: var(--font-default-bold);
    color: #fff;
    background-color: transparent;
    border: none;
    display: inline-block;
    width: 100%;
    padding: calc(20rem/16) calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(60rem/16);
        font-size: calc(24rem/16);
    }
}

.img-gallery-grid .lightbox-img__dynamic-overlay{
    @media screen and (max-width: 767px){
        padding: calc(5rem/16);
        font-size: calc(12rem/16);
    }
}

.accordion-detail .lightbox-img__dynamic-overlay {
    @media screen and (max-width: 767px){
        font-size: 1rem;
        padding: calc(5rem/16);
    }
}

.lightbox-img__dynamic-overlay:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #1F1F1D;
    opacity: 0.6;
    z-index: -1;
    transition: all 200ms ease;
}

.lightbox-img__dynamic-overlay:hover:before{
    opacity: 0.45;
}

.lg-backdrop{
    z-index:2000;
}

.lg-outer{
    z-index: 2001;
}

.lg-backdrop.in {
    opacity: 0.8;
}

.lg-outer .lg-img-wrap{
    overflow: hidden;
}

.lg-outer .lg-img-wrap:before{
    padding-top: 56.25%;
}

.lg-sub-html{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        padding: calc(10rem/16);
    }
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object{
    transform: scaleX(1) translateX(-50%) translateY(-50%);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object{
    transform: scaleX(1) translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
    position: absolute;
    bottom: 0;
}

.js-lightbox-dynamic{
    pointer-events: none;
}

.js-lightbox-dynamic.lightbox-is-init{
    pointer-events: auto;
}

/*
.lg-hide-items .lg-sub-html {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
}*/

.nav-tabs{
    border: none;
}

.nav-tabs .nav-link{
    font-family: var(--font-default-bold);
    font-size: calc(17rem/16);
    border-radius: 0;
    padding: calc(10rem/16) calc(24rem/16);
    border: none;
    border-top: 2px solid var(--color-light-grey);
    opacity: 0.6;
    transition: all 150ms ease;
    
    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
        padding: calc(14rem/16) calc(50rem/16);
    }
    @media screen and (min-width: 1400px){
        font-size: calc(19rem/16);
    }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover{
    background-color: var(--color-light-grey);
    border-color: var(--color-light-grey);
    opacity: 1;
}

.nav-tabs .nav-item:first-child .nav-link{
    border-top-left-radius: calc(8rem/16);
    border-left: 2px solid var(--color-light-grey);
}

.nav-tabs .nav-item:last-child .nav-link{
     border-top-right-radius: calc(8rem/16);
     border-right: 2px solid var(--color-light-grey);
 }

.nav-tabs .nav-item:not(:last-child) .nav-link{
    border-left: 2px solid var(--color-light-grey);

}


@media screen and (max-width: 767px) {

    .nav-tabs--scroll-mobile {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
    }

    .nav-tabs--scroll-mobile .nav-item{
        display: inline-block;
    }

    .nav-tabs--scroll-mobile .nav-link{
        white-space: nowrap;
        padding: calc(10rem/16) calc(15rem/16);
    }

    .nav-tabs.nav-tabs--scroll-mobile .nav-item:not(:last-child) .nav-link{
        border-left-color: transparent;
    }
}
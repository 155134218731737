.video-teaser__title{
    line-height: 1.4;
}

.video-teaser__btn-play{
    font-family: var(--font-default-bold);
}

.video-teaser__btn-play .icon {
    font-size: calc(12rem / 16);
    margin-right: calc(6rem / 16);
    transition: transform 250ms ease;
}

.video-teaser__btn-play:hover .icon{
    transform: translateX(3px);
}


.video-teaser__media .btn-play{
    width: calc(50rem/16);
    height: calc(50rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
    color: #fff;
    flex: calc(14rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    bottom: calc(20rem/16);
    left: calc(20rem/16);
    transition: all 250ms ease;
}


.video-teaser__media:hover .btn-play{
    box-shadow: 0 0 13px 1px rgba(255, 255, 255, 0.65);
}

.video-teaser img{
    border-radius: 4px;
}

.wysiwyg-with-media .video-teaser__media .btn-play{
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (min-width: 768px){
        width: calc(70rem/16);
        height: calc(70rem/16);
    }
}

.img-content-teaser-slider-area.slide{
    padding-bottom: calc(90rem/16);

    @media screen and (min-width: 768px){
        padding-bottom: calc(70rem/16);
    }
}
.img-content-teaser-swiper{
    position: relative;
}

.img-content-teaser-swiper .swiper-navigation{
    position: absolute;
    bottom: calc(-45rem/16);
    right: 0;
}
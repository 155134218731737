
.quickfinder--acco .filter-dropdown .floating-label{
    transform: scale(0.75);
    position: absolute;
    top: calc(2rem/16);

    @media screen and (min-width: 768px){
        top: calc(18rem/16);
    }
}

.quickfinder--acco .filter-dropdown__label{
    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
        display: block;
        margin-top: calc(15rem/16);
    }
}

.quickfinder--acco .filter-dropdown__button.form-control{
    @media screen and (max-width: 767px){
        padding: calc(5rem/16) calc(15rem/16);
        height: calc(50rem/16);
    }
}

.quickfinder--acco .filter-dropdown__icon{
    @media screen and (max-width: 767px){
        font-size: calc(7rem/16);
        right: calc(20rem/16);
    }
}

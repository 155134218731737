.pagination-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination{
    display: inline-flex;
    border: 1px solid var(--color-grey);
}

.page-item{
    position: relative;
}


.page-item:not(:last-child):not(.page-item--arrow):not(.active):after{
    content: '';
    position: absolute;
    right: 0;
    top: calc(13rem/16);
    bottom: calc(13rem/16);
    background-color: var(--color-grey);
    width: calc(1rem/16);
    z-index: 1;
}
.page-item.active:after{
    background-color: transparent;
}

.page-link{
    position: relative;
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    color: var(--color-secondary);
    border: 0;
    padding: calc(9rem/16) calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
        padding: calc(14rem/16) calc(18rem/16);
    }
}

.page-link:before{
    content: '';
    position: absolute;
    left: calc(3rem/16);
    right: calc(3rem/16);
    top: calc(3rem/16);
    bottom: calc(3rem/16);
    opacity: 0;
    background-color: var(--color-primary);
    border-radius: calc(4rem/16);
    z-index: -1;
}

.page-item.active:not(.page-item--arrow) .page-link:before,
.page-item:not(.page-item--arrow) .page-link:hover:before{
    opacity: 1;
}

.page-item.active .page-link,
.page-item .page-link:hover{
    background-color: #fff;
    border: 0;
    color: #fff;
}

.page-item--arrow .page-link{
    color: var(--color-primary);
    background-color: transparent;
}

.page-item--arrow .page-link:hover{
    background-color: transparent;
    color: var(--color-secondary);
}
.quickfinder{
    max-width: calc(1595rem/16);
    width: 100%;
    margin: 0 auto;
}

.quickfinder:not(.quickfinder--acco){
    @media screen and (max-width: 767px){
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        padding: calc(70rem/16) calc(25rem/16);
        transform: translateX(100%);
        transition: transform 250ms ease;
        z-index: 105;
        overflow-y: auto;
    }
}

.is-open .quickfinder{
    @media screen and (max-width: 767px){
       transform: translateX(0);
    }
}

.quickfinder__form-control{
    @media screen and (min-width: 768px){
       border: 0;
    }
    @media screen and (max-width: 767px){
       padding: calc(18rem/16) calc(15rem/16) calc(8rem/16);
    }
}

.quickfinder-overlay .quickfinder__guest-dropdown.dropdown--arrow .dropdown-menu:before{
    @media screen and (max-width: 767px){
        display: none;
    }
}

.quickfinder-overlay .quickfinder__guest-dropdown .dropdown-menu{
    @media screen and (max-width: 767px){
        top: 0;
    }
}

.quickfinder-overlay-toggle .quickfinder__form-control{
    @media screen and (max-width: 767px){
        padding: calc(25rem/16) calc(15rem/16) calc(8rem/16);
    }
}

.quickfinder-overlay-label{
    @media screen and (max-width: 767px){
        transform: translateY(-1.4375rem) scale(.8)!important;
    }
}

.quickfinder__form-control.form-control:disabled{
    background-color: #fff;
}

.quickfinder .form-control{
    border-radius: 0;
}

.quickfinder__first-input{
    border-top-left-radius: calc(4rem/16);
    border-bottom-left-radius: calc(4rem/16);
}

.quickfinder .dropdown-toggle{
    background-color: #fff;
    border-radius: 0;
    
    @media screen and (max-width: 767px){
       border: 1px solid var(--color-bg-dark);
    }
}

.quickfinder__guest-dropdown .dropdown-toggle:after{
    display: none;
}

.quickfinder__guest-dropdown .quickfinder__form-group-label{
    top: calc(2rem/16);
    @media screen and (min-width: 768px){
        top: calc(20rem/16);
    }
}

.quickfinder .btn-quickfinder-submit{
    @media screen and (min-width: 768px){
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


.quickfinder__form-group{
    height: calc(50rem/16);
    font-size: calc(18rem/16);
    padding: calc(6rem/16) calc(15rem/16);
    /* line-height: 1.5; */
    display: flex;
    align-items: flex-end;
    font-family:  var(--font-default-bold);
    color: var(--color-secondary);

    @media screen and (min-width: 768px){
        height: calc(89rem/16);
        padding: calc(34rem/16) calc(25rem/16) calc(20rem/16);

    }
}

.accommodation-filter .quickfinder__form-group{
    height: calc(60rem/16);
    background-color: #fff;
    border-radius: calc(8rem/16);
    
    @media screen and (min-width: 768px){
        padding: calc(34rem/16) calc(25rem/16) calc(8rem/16);
    }
}

.quickfinder__form-group-label{
    font-size: calc(18rem/16);
    color: var(--color-text-muted);
    transform: scale(0.75);
    position: absolute;
    top: 0;
    left: calc(11rem/16);

    @media screen and (min-width: 768px){
        top: calc(14rem/16);
        left: calc(20rem/16);
    }
}




.accommodation-filter .quickfinder__form-group-label{
    top: calc(2rem/16);
}

.quickfinder__guest-text{
    display: inline-block;
    position: relative;
}

.quickfinder__guest-text+.quickfinder__guest-text{
    padding-left: calc(10rem/16);
    margin-left: calc(10rem/16);
}

.quickfinder__guest-text:not(:first-child):before{
    content: '';
    position: absolute;
    left: 0;
    top: calc(5rem/16);
    width: 2px;
    bottom: calc(5rem/16);
    background-color: var(--color-text-muted);
}

.quickfinder__arrow{
    color: var(--color-primary);
    position: absolute;
    right: calc(20rem/16);
    bottom: calc(25rem/16);
    font-size: calc(7rem/16);
}



.quickfinder-overlay-toggle__btn{
    @media screen and (max-width: 767px){
        font-size: calc(16rem/16);
        letter-spacing: 0.5px;
        width: calc(130rem/16);
        padding: calc(10rem/16) calc(15rem/16);
        line-height: calc(20/16);
    }
}

.quickfinder__child-error-message{
    position: absolute;
    bottom: calc(-9rem/16);
    left: 50%;
    transform: translateY(100%) translateX(-50%);
    background: var(--color-danger);
    color: #fff;
    font-size: calc(13rem/16);
    padding: calc(5rem/16);
    border-radius: calc(4rem/16);
    z-index: 10;
}

.quickfinder__child-error-message:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent var(--color-danger) transparent;
}

.quickfinder__close{
    position: absolute;
    right: calc(25rem/16);
    top: calc(20rem/16);
    background-color: transparent;
    border: none;
    color: var(--color-text-muted);
    line-height: 0;
    padding: calc(8rem/16);
}

.quickfinder__close .icon{
    transform: rotate(45deg);
}

.quickfinder-row>.col-md{
    border-top-left-radius: calc(4rem/16);
    border-bottom-left-radius: calc(4rem/16);
}

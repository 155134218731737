.accommodation-content-teaser-row .swiper-container{
    overflow: visible;
}

.accommodation-teaser{
    background-color: #fff;
    border-radius: calc(4rem/16);

    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    overflow: hidden;

    transition: all 250ms ease;
}

.accommodation-teaser:hover{
    transform: translateY(-4px);
}

.accommodation-teaser__img{
    position: relative;
}

.accommodation-teaser__img img{
    border-radius: 4px 4px 0 0;
}

.accommodation-teaser .btn-wishlist:not(.stretch-link__link){
    position: absolute;
    top: calc(15rem/16);
    right: calc(15rem/16);
    width: calc(27rem/16);
    height: calc(27rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    color: var(--color-danger);
    font-size: calc(18rem/16);
}

.accommodation-teaser__ribbon{
    width: calc(240rem/16);
    padding: calc(6rem/16) 0;
    background: #fff;
    color: var(--color-primary);
    position: absolute;
    top: 0;
    font-size: calc(11rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    text-align: center;
    transform: rotate(-45deg) translateY(-47px) translateX(-61px) scale(.8);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        transform: rotate(-41deg) translateY(-27px) translateX(-58px);
    }
}

.accommodation-teaser__ribbon-text{
    width: 100%;
    display: block;
    padding: calc(9rem/16) calc(50rem/16);
    border-top: 2px dashed;
    border-bottom: 2px dashed;
}

.accommodation-teaser__top{
    padding: calc(15rem/16) calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(20rem/16);
    }
}

.accommodation-teaser__title{
    line-height: calc(32/30);
    margin-bottom: calc(3rem/16);
    
    @media screen and (min-width: 768px) and (max-width: 1199px){
       font-size: calc(25rem/16);
    }
    @media screen and (min-width: 1200px) and (max-width: 1400px){
        font-size: calc(23rem/16);
    }

    @media screen and (min-width: 1400px) and (max-width: 1800px){
        font-size: calc(26rem/16);
    }
}

.accommodation-overview-list .accommodation-teaser__title{
    font-size: calc(25rem/16);
}

.accommodation-teaser__location{
    font-size: calc(15rem/16);
    color: var(--color-text-muted);

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
    }
}

.accommodation-teaser__body{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.accommodation-teaser__rating{
    padding: calc(10rem/16) calc(15rem/16);
    border-top: 1px solid var(--color-light-grey);
    border-bottom: 1px solid var(--color-light-grey);

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(20rem/16);
    }
}

.accommodation-teaser__footer{
    padding: calc(15rem/16) calc(15rem/16) calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(20rem/16) calc(20rem/16);
    }
}

.accommodation-teaser__badges-and-price{
    @media screen and (min-width: 768px){
        min-height: calc(40rem/16);
        margin-bottom: calc(5rem/16);
    }

    margin-bottom: calc(15rem/16);
}

.accommodation-teaser__price{
    font-size: calc(21rem/16);
    line-height: 1;
    color: var(--color-secondary);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
    }
}

.accommodation-teaser__price-info{
    color: var(--color-text-muted);
    font-size: calc(14rem/16);
}

.accommodation-teaser__mealplan.tooltip-btn{
    background-color: var(--color-primary);
    color: #fff;
    margin-right: calc(10rem/16);
}

.accommodation-teaser .link-primary{
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
}

.accommodation-teaser .btn-acco-booking{
    @media screen and (min-width: 1400px){
       padding: calc(10rem/16) calc(30rem/16);
        font-size: calc(19rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(8rem/16) calc(25rem/16);
        font-size: calc(18rem/16);

    }
}

.btn.acco-pool-enquiry-btn:not(.stretch-link__link){
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: var(--color-default);
    opacity: 0.8;
    color: #fff;
    font-family: var(--font-default-bold);
    width: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    font-size: calc(22rem/16);
}

.btn.acco-pool-enquiry-btn.active:not(.stretch-link__link){
    background-color: var(--color-primary);
}

.enquiry-active .btn.acco-pool-enquiry-btn:not(.stretch-link__link){
    display: flex;
}

.acco-pool-enquiry-btn.active .acco-pool-enquiry-btn__add{
    display: none;
}

.acco-pool-enquiry-btn__remove{
    display: none;
}

.acco-pool-enquiry-btn.active .acco-pool-enquiry-btn__remove{
    display: block;
}

.acco-pool-enquiry-btn .icon{
    vertical-align: calc(-4rem/16);
    margin-left: calc(6rem/16);
}

/* horizontal */

.accommodation-teaser--horizontal .accommodation-teaser__img img{
    border-radius: 4px 0 0 4px;
}

.accommodation-teaser--horizontal .accommodation-teaser__rating {
    padding: calc(10rem / 16) 0;
}

.accommodation-teaser--horizontal .accommodation-teaser__footer{
    padding-top: 0;
}

.accommodation-teaser .btn-acco-booking{
    @media screen and (max-width: 767px){
       padding: calc(6rem/16) calc(20rem/16);
    }
}

.accommodation-teaser--content .btn-acco-booking{
    @media screen and (max-width: 767px){
       font-size: calc(17rem/16);
        padding: calc(6rem/16) calc(14rem/16);
    }
}
.external-link-item{
    background-color: #fff;
    padding: calc(30rem/16) calc(20rem/16);
}

.slide-white-bg .external-link-item{
    background-color: var(--color-bg);
}

.external-link-item__title{
    font-family: var(--font-amorie);
    margin-bottom: calc(20rem/16);
}

.is-uab .external-link-item__title{
    color: var(--color-uab);
}

.is-pzg .external-link-item__title{
    color: var(--color-pzg);
}
.quote{
    text-align: center;
    overflow: hidden;
    padding-bottom: calc(30rem/16);
    border-bottom: 2px dashed var(--color-grey);

    @media screen and (min-width: 768px){
        padding-bottom: calc(50rem/16);
    }
}

.quote__img{
    width: calc(125em/16);
    height: calc(125rem/16);
    box-shadow: 0 15px 15px 0 rgba(0,0,0,0.1);
    border-radius: 50%;
    border: 7px solid #fff;
    margin: 0 auto;
    position: relative;
    overflow: visible;

    @media screen and (min-width: 768px){
        width: calc(185rem/16);
        height: calc(185rem/16);
        border: 11px solid #fff;
    }
}

.quote__img img{
    border-radius: 50%;
}

.quote__img:after{
    content: '';
    position: absolute;
    top: 50%;
    right: calc(120rem/16);
    width: calc(1000rem/16);
    transform: translateY(-50%);
    height: calc(2rem/16);
    border-top: 2px dashed var(--color-grey);
    z-index: 1;

    @media screen and (min-width: 768px){
        right: calc(178rem/16);
    }
}

.quote__img:before{
    content: '';
    position: absolute;
    top: 50%;
    left: calc(120rem/16);
    width: calc(1000rem/16);
    transform: translateY(-50%);
    height: calc(2rem/16);
    border-top: 2px dashed var(--color-grey);
    z-index: 1;

    @media screen and (min-width: 768px){
        left: calc(178rem/16);
    }
}

.quote__text{
    font-size: calc(20rem/16);
    line-height: calc(36/25);
    letter-spacing: calc(1rem/16);
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);

    }
}

.quote__author{
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    margin-top: calc(15rem/16);
}
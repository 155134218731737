html {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(29/20);
/*    word-break: break-word;*/
    
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: normal;
}

.font-amorie {
    font-family: var(--font-amorie);
    font-weight: normal;
}

b,
strong,
.strong,
.font-bold{
    font-family: var(--font-default-bold);
    font-weight: normal;
}

small,
.small{
    font-size: calc(12rem/16);
    letter-spacing: calc(1rem/16);
    line-height: 1.4;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    margin-bottom: 0;
}

h1{
    @media screen and (max-width: 767px){
        margin-bottom: calc(8rem/16);
    }
}

h1, .h1 {
    font-size: calc(45rem/16);
    line-height: calc(40/45);
    font-family: var(--font-amorie);

    @media screen and (min-width: 768px){
       font-size: calc(60rem/16);
        line-height: calc(69/60);
    }
}
h2, .h2 {
    font-size: calc(28rem/16);
    line-height: calc(69/60);

    @media screen and (min-width: 768px){
        font-size: calc(55rem/16);
    }
}
h3, .h3 {
    font-size: calc(23rem/16);
    line-height: calc(34/30);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
    }
}
h4, .h4 {
    font-size: calc(20rem/16);
    line-height: calc(31/20);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}
h5, .h5 {
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
}
h6, .h6 {
    font-size: 1em;
    font-family: var(--font-default-bold);
}

table{
    word-break: initial;
}
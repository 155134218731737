.location-area__hl{
    color: var(--color-primary);
    font-family: var(--font-amorie);
    margin-bottom: calc(20rem/16);
}

.location-area__calculate-route .btn{
    @media screen and (max-width: 767px){
       padding: calc(6rem/16) calc(12rem/16);
        font-size: calc(16rem/16);
    }
}

.shariff .orientation-vertical li, .shariff .orientation-vertical li a {
    display: flex;
}
.shariff .orientation-horizontal li, .shariff .orientation-horizontal li a {
    display: inline-flex;
}

.shariff ul {
    padding-left: 0;
    list-style: none;
    margin-top: 0;
}

.shariff li {
    border: 1px solid #fff;
}

.shariff li, .shariff li a {
    color: #fff;
    background-color: transparent;
    text-align:center;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size:calc(17rem/16);
    width: calc(45rem/16);
    height:calc(45rem/16);
    transition: all 0.2s ease;
}

.shariff li:hover, .shariff li:hover a {
    color: var(--color-default);
    background-color: #fff;
}

.shariff li + li {
    margin-left: calc(4rem/16);
}
.shariff-button .fab, .shariff-button .fas {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}

.shariff-button.facebook .fab:before {
    content:var(--icon-facebook);
}

.shariff-button.twitter .fab:before {
    content:var(--icon-twitter);
}

.shariff-button.pinterest .fab:before {
    content:var(--icon-pinterest);
}

.shariff-button.mail .fas:before {
    content:var(--icon-mail);
}

.shariff-button.whatsapp .fab:before {
    content:var(--icon-whatsapp);
}

.shariff-button.addthis .fas:before {
    content:var(--icon-addthis);
}

.shariff-button.linkedin .fab:before {
    content:var(--icon-linkedin);
}
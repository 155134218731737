@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1731926607425/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1731926607425/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1731926607425/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-360:before { content: "\EA01" }
.icon-3d-glasses:before { content: "\EA02" }
.icon-addthis:before { content: "\EA03" }
.icon-alarm:before { content: "\EA04" }
.icon-animal:before { content: "\EA05" }
.icon-apartment:before { content: "\EA06" }
.icon-apple:before { content: "\EA07" }
.icon-archery:before { content: "\EA08" }
.icon-arrow-down:before { content: "\EA09" }
.icon-arrow-dropdown:before { content: "\EA0A" }
.icon-arrow-left-small:before { content: "\EA0B" }
.icon-arrow-left:before { content: "\EA0C" }
.icon-arrow-long-right:before { content: "\EA0D" }
.icon-arrow-right-small:before { content: "\EA0E" }
.icon-arrow-right-thin:before { content: "\EA0F" }
.icon-arrow-right:before { content: "\EA10" }
.icon-at-sign:before { content: "\EA11" }
.icon-aufwand:before { content: "\EA12" }
.icon-baby-bottle:before { content: "\EA13" }
.icon-baby:before { content: "\EA14" }
.icon-bag:before { content: "\EA15" }
.icon-balance:before { content: "\EA16" }
.icon-balloon:before { content: "\EA17" }
.icon-baseball:before { content: "\EA18" }
.icon-bathtub:before { content: "\EA19" }
.icon-bed:before { content: "\EA1A" }
.icon-bicycle:before { content: "\EA1B" }
.icon-binoculars:before { content: "\EA1C" }
.icon-bird:before { content: "\EA1D" }
.icon-blatt:before { content: "\EA1E" }
.icon-boat:before { content: "\EA1F" }
.icon-book:before { content: "\EA20" }
.icon-bookmark:before { content: "\EA21" }
.icon-bottle:before { content: "\EA22" }
.icon-bottle2:before { content: "\EA23" }
.icon-bread:before { content: "\EA24" }
.icon-broom:before { content: "\EA25" }
.icon-bubble-heart:before { content: "\EA26" }
.icon-bubbles:before { content: "\EA27" }
.icon-bus:before { content: "\EA28" }
.icon-cactus:before { content: "\EA29" }
.icon-cake:before { content: "\EA2A" }
.icon-calendar:before { content: "\EA2B" }
.icon-camera:before { content: "\EA2C" }
.icon-car-battery:before { content: "\EA2D" }
.icon-car-lock:before { content: "\EA2E" }
.icon-car:before { content: "\EA2F" }
.icon-carrot:before { content: "\EA30" }
.icon-cart:before { content: "\EA31" }
.icon-cat:before { content: "\EA32" }
.icon-chair:before { content: "\EA33" }
.icon-chart-growth:before { content: "\EA34" }
.icon-chat:before { content: "\EA35" }
.icon-check:before { content: "\EA36" }
.icon-cheese:before { content: "\EA37" }
.icon-chef:before { content: "\EA38" }
.icon-cherry:before { content: "\EA39" }
.icon-chevron-left-circle:before { content: "\EA3A" }
.icon-christmas:before { content: "\EA3B" }
.icon-circle-green:before { content: "\EA3C" }
.icon-city:before { content: "\EA3D" }
.icon-clock:before { content: "\EA3E" }
.icon-close:before { content: "\EA3F" }
.icon-cloud-hailstones:before { content: "\EA40" }
.icon-cloud-sun:before { content: "\EA41" }
.icon-cloud:before { content: "\EA42" }
.icon-coffee-cup:before { content: "\EA43" }
.icon-color-sampler:before { content: "\EA44" }
.icon-contactform:before { content: "\EA45" }
.icon-cook:before { content: "\EA46" }
.icon-cool:before { content: "\EA47" }
.icon-coolant-temperature:before { content: "\EA48" }
.icon-cord:before { content: "\EA49" }
.icon-couch:before { content: "\EA4A" }
.icon-cow:before { content: "\EA4B" }
.icon-credit-card:before { content: "\EA4C" }
.icon-crown:before { content: "\EA4D" }
.icon-cube:before { content: "\EA4E" }
.icon-database:before { content: "\EA4F" }
.icon-diamond:before { content: "\EA50" }
.icon-dice:before { content: "\EA51" }
.icon-dinner:before { content: "\EA52" }
.icon-document:before { content: "\EA53" }
.icon-dog:before { content: "\EA54" }
.icon-download-arrow:before { content: "\EA55" }
.icon-download:before { content: "\EA56" }
.icon-dream:before { content: "\EA57" }
.icon-drop:before { content: "\EA58" }
.icon-egg:before { content: "\EA59" }
.icon-elements:before { content: "\EA5A" }
.icon-expand:before { content: "\EA5B" }
.icon-external-link:before { content: "\EA5C" }
.icon-eye:before { content: "\EA5D" }
.icon-facebook:before { content: "\EA5E" }
.icon-fahrrad:before { content: "\EA5F" }
.icon-fan:before { content: "\EA60" }
.icon-feather:before { content: "\EA61" }
.icon-fence:before { content: "\EA62" }
.icon-fire:before { content: "\EA63" }
.icon-fish:before { content: "\EA64" }
.icon-flag:before { content: "\EA65" }
.icon-flare:before { content: "\EA66" }
.icon-football:before { content: "\EA67" }
.icon-footprint:before { content: "\EA68" }
.icon-gift:before { content: "\EA69" }
.icon-glass:before { content: "\EA6A" }
.icon-glass2:before { content: "\EA6B" }
.icon-golf:before { content: "\EA6C" }
.icon-googleplus:before { content: "\EA6D" }
.icon-grapes:before { content: "\EA6E" }
.icon-group-work:before { content: "\EA6F" }
.icon-hammer:before { content: "\EA70" }
.icon-hand-waving:before { content: "\EA71" }
.icon-hand:before { content: "\EA72" }
.icon-happy:before { content: "\EA73" }
.icon-haus:before { content: "\EA74" }
.icon-heart-outline:before { content: "\EA75" }
.icon-heart:before { content: "\EA76" }
.icon-hearts:before { content: "\EA77" }
.icon-helicopter:before { content: "\EA78" }
.icon-hockey:before { content: "\EA79" }
.icon-home:before { content: "\EA7A" }
.icon-horse:before { content: "\EA7B" }
.icon-hourglass:before { content: "\EA7C" }
.icon-house:before { content: "\EA7D" }
.icon-ice-cream:before { content: "\EA7E" }
.icon-info-square:before { content: "\EA7F" }
.icon-info:before { content: "\EA80" }
.icon-instagram:before { content: "\EA81" }
.icon-jump:before { content: "\EA82" }
.icon-key:before { content: "\EA83" }
.icon-kochloeffel:before { content: "\EA84" }
.icon-lamp:before { content: "\EA85" }
.icon-landscape:before { content: "\EA86" }
.icon-leaf:before { content: "\EA87" }
.icon-library:before { content: "\EA88" }
.icon-license:before { content: "\EA89" }
.icon-link:before { content: "\EA8A" }
.icon-linkedin:before { content: "\EA8B" }
.icon-location-pin:before { content: "\EA8C" }
.icon-lollipop:before { content: "\EA8D" }
.icon-lotus:before { content: "\EA8E" }
.icon-magnet:before { content: "\EA8F" }
.icon-mail:before { content: "\EA90" }
.icon-mailbox:before { content: "\EA91" }
.icon-man-woman:before { content: "\EA92" }
.icon-map-folded:before { content: "\EA93" }
.icon-map-marker:before { content: "\EA94" }
.icon-map:before { content: "\EA95" }
.icon-medal-empty:before { content: "\EA96" }
.icon-minus:before { content: "\EA97" }
.icon-moon:before { content: "\EA98" }
.icon-muell:before { content: "\EA99" }
.icon-music-note:before { content: "\EA9A" }
.icon-mustache-glasses:before { content: "\EA9B" }
.icon-palette:before { content: "\EA9C" }
.icon-paper-plane:before { content: "\EA9D" }
.icon-parking:before { content: "\EA9E" }
.icon-paw:before { content: "\EA9F" }
.icon-phone:before { content: "\EAA0" }
.icon-picture:before { content: "\EAA1" }
.icon-pig:before { content: "\EAA2" }
.icon-pine-tree:before { content: "\EAA3" }
.icon-ping-pong:before { content: "\EAA4" }
.icon-pinterest:before { content: "\EAA5" }
.icon-pizza:before { content: "\EAA6" }
.icon-platter:before { content: "\EAA7" }
.icon-play:before { content: "\EAA8" }
.icon-playlist:before { content: "\EAA9" }
.icon-plus-circle:before { content: "\EAAA" }
.icon-plus:before { content: "\EAAB" }
.icon-pointer-right:before { content: "\EAAC" }
.icon-presentation:before { content: "\EAAD" }
.icon-printer:before { content: "\EAAE" }
.icon-pulse:before { content: "\EAAF" }
.icon-pushpin:before { content: "\EAB0" }
.icon-puzzle:before { content: "\EAB1" }
.icon-quote:before { content: "\EAB2" }
.icon-rabbit:before { content: "\EAB3" }
.icon-reading:before { content: "\EAB4" }
.icon-register:before { content: "\EAB5" }
.icon-road-sign:before { content: "\EAB6" }
.icon-road:before { content: "\EAB7" }
.icon-roggen:before { content: "\EAB8" }
.icon-sausage:before { content: "\EAB9" }
.icon-saw:before { content: "\EABA" }
.icon-scissors:before { content: "\EABB" }
.icon-screwdriver:before { content: "\EABC" }
.icon-sheep:before { content: "\EABD" }
.icon-shield:before { content: "\EABE" }
.icon-shoe:before { content: "\EABF" }
.icon-shovel:before { content: "\EAC0" }
.icon-slider-active:before { content: "\EAC1" }
.icon-slider-inactive:before { content: "\EAC2" }
.icon-smile:before { content: "\EAC3" }
.icon-snow:before { content: "\EAC4" }
.icon-soccer:before { content: "\EAC5" }
.icon-socks:before { content: "\EAC6" }
.icon-speed-medium:before { content: "\EAC7" }
.icon-star:before { content: "\EAC8" }
.icon-steak:before { content: "\EAC9" }
.icon-suche:before { content: "\EACA" }
.icon-sun-wind:before { content: "\EACB" }
.icon-sun:before { content: "\EACC" }
.icon-sunflower:before { content: "\EACD" }
.icon-swim:before { content: "\EACE" }
.icon-tag:before { content: "\EACF" }
.icon-teacup:before { content: "\EAD0" }
.icon-teapot:before { content: "\EAD1" }
.icon-teilen:before { content: "\EAD2" }
.icon-tennis:before { content: "\EAD3" }
.icon-thumbs-up:before { content: "\EAD4" }
.icon-time:before { content: "\EAD5" }
.icon-tissue:before { content: "\EAD6" }
.icon-train:before { content: "\EAD7" }
.icon-tree:before { content: "\EAD8" }
.icon-trophy:before { content: "\EAD9" }
.icon-tv:before { content: "\EADA" }
.icon-twitter:before { content: "\EADB" }
.icon-umbrella2:before { content: "\EADC" }
.icon-user:before { content: "\EADD" }
.icon-users-plus:before { content: "\EADE" }
.icon-users:before { content: "\EADF" }
.icon-walk:before { content: "\EAE0" }
.icon-website:before { content: "\EAE1" }
.icon-whatsapp:before { content: "\EAE2" }
.icon-wheelchair:before { content: "\EAE3" }
.icon-wifi:before { content: "\EAE4" }
.icon-wind:before { content: "\EAE5" }
.icon-wine:before { content: "\EAE6" }
.icon-world:before { content: "\EAE7" }
.icon-youtube:before { content: "\EAE8" }
.icon-zoom:before { content: "\EAE9" }


:root {
--icon-360: "\EA01";
    --icon-3d-glasses: "\EA02";
    --icon-addthis: "\EA03";
    --icon-alarm: "\EA04";
    --icon-animal: "\EA05";
    --icon-apartment: "\EA06";
    --icon-apple: "\EA07";
    --icon-archery: "\EA08";
    --icon-arrow-down: "\EA09";
    --icon-arrow-dropdown: "\EA0A";
    --icon-arrow-left-small: "\EA0B";
    --icon-arrow-left: "\EA0C";
    --icon-arrow-long-right: "\EA0D";
    --icon-arrow-right-small: "\EA0E";
    --icon-arrow-right-thin: "\EA0F";
    --icon-arrow-right: "\EA10";
    --icon-at-sign: "\EA11";
    --icon-aufwand: "\EA12";
    --icon-baby-bottle: "\EA13";
    --icon-baby: "\EA14";
    --icon-bag: "\EA15";
    --icon-balance: "\EA16";
    --icon-balloon: "\EA17";
    --icon-baseball: "\EA18";
    --icon-bathtub: "\EA19";
    --icon-bed: "\EA1A";
    --icon-bicycle: "\EA1B";
    --icon-binoculars: "\EA1C";
    --icon-bird: "\EA1D";
    --icon-blatt: "\EA1E";
    --icon-boat: "\EA1F";
    --icon-book: "\EA20";
    --icon-bookmark: "\EA21";
    --icon-bottle: "\EA22";
    --icon-bottle2: "\EA23";
    --icon-bread: "\EA24";
    --icon-broom: "\EA25";
    --icon-bubble-heart: "\EA26";
    --icon-bubbles: "\EA27";
    --icon-bus: "\EA28";
    --icon-cactus: "\EA29";
    --icon-cake: "\EA2A";
    --icon-calendar: "\EA2B";
    --icon-camera: "\EA2C";
    --icon-car-battery: "\EA2D";
    --icon-car-lock: "\EA2E";
    --icon-car: "\EA2F";
    --icon-carrot: "\EA30";
    --icon-cart: "\EA31";
    --icon-cat: "\EA32";
    --icon-chair: "\EA33";
    --icon-chart-growth: "\EA34";
    --icon-chat: "\EA35";
    --icon-check: "\EA36";
    --icon-cheese: "\EA37";
    --icon-chef: "\EA38";
    --icon-cherry: "\EA39";
    --icon-chevron-left-circle: "\EA3A";
    --icon-christmas: "\EA3B";
    --icon-circle-green: "\EA3C";
    --icon-city: "\EA3D";
    --icon-clock: "\EA3E";
    --icon-close: "\EA3F";
    --icon-cloud-hailstones: "\EA40";
    --icon-cloud-sun: "\EA41";
    --icon-cloud: "\EA42";
    --icon-coffee-cup: "\EA43";
    --icon-color-sampler: "\EA44";
    --icon-contactform: "\EA45";
    --icon-cook: "\EA46";
    --icon-cool: "\EA47";
    --icon-coolant-temperature: "\EA48";
    --icon-cord: "\EA49";
    --icon-couch: "\EA4A";
    --icon-cow: "\EA4B";
    --icon-credit-card: "\EA4C";
    --icon-crown: "\EA4D";
    --icon-cube: "\EA4E";
    --icon-database: "\EA4F";
    --icon-diamond: "\EA50";
    --icon-dice: "\EA51";
    --icon-dinner: "\EA52";
    --icon-document: "\EA53";
    --icon-dog: "\EA54";
    --icon-download-arrow: "\EA55";
    --icon-download: "\EA56";
    --icon-dream: "\EA57";
    --icon-drop: "\EA58";
    --icon-egg: "\EA59";
    --icon-elements: "\EA5A";
    --icon-expand: "\EA5B";
    --icon-external-link: "\EA5C";
    --icon-eye: "\EA5D";
    --icon-facebook: "\EA5E";
    --icon-fahrrad: "\EA5F";
    --icon-fan: "\EA60";
    --icon-feather: "\EA61";
    --icon-fence: "\EA62";
    --icon-fire: "\EA63";
    --icon-fish: "\EA64";
    --icon-flag: "\EA65";
    --icon-flare: "\EA66";
    --icon-football: "\EA67";
    --icon-footprint: "\EA68";
    --icon-gift: "\EA69";
    --icon-glass: "\EA6A";
    --icon-glass2: "\EA6B";
    --icon-golf: "\EA6C";
    --icon-googleplus: "\EA6D";
    --icon-grapes: "\EA6E";
    --icon-group-work: "\EA6F";
    --icon-hammer: "\EA70";
    --icon-hand-waving: "\EA71";
    --icon-hand: "\EA72";
    --icon-happy: "\EA73";
    --icon-haus: "\EA74";
    --icon-heart-outline: "\EA75";
    --icon-heart: "\EA76";
    --icon-hearts: "\EA77";
    --icon-helicopter: "\EA78";
    --icon-hockey: "\EA79";
    --icon-home: "\EA7A";
    --icon-horse: "\EA7B";
    --icon-hourglass: "\EA7C";
    --icon-house: "\EA7D";
    --icon-ice-cream: "\EA7E";
    --icon-info-square: "\EA7F";
    --icon-info: "\EA80";
    --icon-instagram: "\EA81";
    --icon-jump: "\EA82";
    --icon-key: "\EA83";
    --icon-kochloeffel: "\EA84";
    --icon-lamp: "\EA85";
    --icon-landscape: "\EA86";
    --icon-leaf: "\EA87";
    --icon-library: "\EA88";
    --icon-license: "\EA89";
    --icon-link: "\EA8A";
    --icon-linkedin: "\EA8B";
    --icon-location-pin: "\EA8C";
    --icon-lollipop: "\EA8D";
    --icon-lotus: "\EA8E";
    --icon-magnet: "\EA8F";
    --icon-mail: "\EA90";
    --icon-mailbox: "\EA91";
    --icon-man-woman: "\EA92";
    --icon-map-folded: "\EA93";
    --icon-map-marker: "\EA94";
    --icon-map: "\EA95";
    --icon-medal-empty: "\EA96";
    --icon-minus: "\EA97";
    --icon-moon: "\EA98";
    --icon-muell: "\EA99";
    --icon-music-note: "\EA9A";
    --icon-mustache-glasses: "\EA9B";
    --icon-palette: "\EA9C";
    --icon-paper-plane: "\EA9D";
    --icon-parking: "\EA9E";
    --icon-paw: "\EA9F";
    --icon-phone: "\EAA0";
    --icon-picture: "\EAA1";
    --icon-pig: "\EAA2";
    --icon-pine-tree: "\EAA3";
    --icon-ping-pong: "\EAA4";
    --icon-pinterest: "\EAA5";
    --icon-pizza: "\EAA6";
    --icon-platter: "\EAA7";
    --icon-play: "\EAA8";
    --icon-playlist: "\EAA9";
    --icon-plus-circle: "\EAAA";
    --icon-plus: "\EAAB";
    --icon-pointer-right: "\EAAC";
    --icon-presentation: "\EAAD";
    --icon-printer: "\EAAE";
    --icon-pulse: "\EAAF";
    --icon-pushpin: "\EAB0";
    --icon-puzzle: "\EAB1";
    --icon-quote: "\EAB2";
    --icon-rabbit: "\EAB3";
    --icon-reading: "\EAB4";
    --icon-register: "\EAB5";
    --icon-road-sign: "\EAB6";
    --icon-road: "\EAB7";
    --icon-roggen: "\EAB8";
    --icon-sausage: "\EAB9";
    --icon-saw: "\EABA";
    --icon-scissors: "\EABB";
    --icon-screwdriver: "\EABC";
    --icon-sheep: "\EABD";
    --icon-shield: "\EABE";
    --icon-shoe: "\EABF";
    --icon-shovel: "\EAC0";
    --icon-slider-active: "\EAC1";
    --icon-slider-inactive: "\EAC2";
    --icon-smile: "\EAC3";
    --icon-snow: "\EAC4";
    --icon-soccer: "\EAC5";
    --icon-socks: "\EAC6";
    --icon-speed-medium: "\EAC7";
    --icon-star: "\EAC8";
    --icon-steak: "\EAC9";
    --icon-suche: "\EACA";
    --icon-sun-wind: "\EACB";
    --icon-sun: "\EACC";
    --icon-sunflower: "\EACD";
    --icon-swim: "\EACE";
    --icon-tag: "\EACF";
    --icon-teacup: "\EAD0";
    --icon-teapot: "\EAD1";
    --icon-teilen: "\EAD2";
    --icon-tennis: "\EAD3";
    --icon-thumbs-up: "\EAD4";
    --icon-time: "\EAD5";
    --icon-tissue: "\EAD6";
    --icon-train: "\EAD7";
    --icon-tree: "\EAD8";
    --icon-trophy: "\EAD9";
    --icon-tv: "\EADA";
    --icon-twitter: "\EADB";
    --icon-umbrella2: "\EADC";
    --icon-user: "\EADD";
    --icon-users-plus: "\EADE";
    --icon-users: "\EADF";
    --icon-walk: "\EAE0";
    --icon-website: "\EAE1";
    --icon-whatsapp: "\EAE2";
    --icon-wheelchair: "\EAE3";
    --icon-wifi: "\EAE4";
    --icon-wind: "\EAE5";
    --icon-wine: "\EAE6";
    --icon-world: "\EAE7";
    --icon-youtube: "\EAE8";
    --icon-zoom: "\EAE9";
    
}
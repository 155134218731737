.img-border{
    border-radius: 2px;
    transform-origin: left top;
    transform: rotate(-4deg);
}

.img-border--rotate-right{
    transform: rotate(4deg);
}

.col-divider{
    position: relative;
}

.col-divider:before{
    @media screen and (min-width: 768px){
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 100%;
        border-left: 2px solid transparent;
        border-right: 2px dashed #e6e0d8;
        border-bottom: 2px solid transparent;
    }

}

.ch2-icon{
    z-index: 1000!important; /* for cookie icon */
}
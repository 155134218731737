.breadcrumb-wrapper.bg-decorator:after{
    @media screen and (min-width: 768px){
       height: calc(52rem/16);
    }
}

body.is-white .breadcrumb-wrapper.bg-decorator:after{
    background: transparent url(/static/img/backgrounds/decorator-bottom.svg) no-repeat 100% 0;
}

.breadcrumb {
    position: relative;
    z-index: 2;
    margin-top: 1rem;
    @media screen and (min-width: 768px) {
        margin-top: calc(35rem/16);
    }
}
.breadcrumb-item {
    font-size: calc(14rem/16);
    letter-spacing: 0.5px;
@media screen and (min-width: 768px) {
        font-size: 1rem;
        letter-spacing: 1.5px;
    }
}
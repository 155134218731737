.navbar-parent{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3000;
}

.lg-on .navbar-parent{
    z-index: 50;
}

.navbar-parent.fade-in{
    opacity: 1;
}

.navbar-container{
    z-index: 2;

    @media screen and (min-width: 768px){
        width: 100%;
        max-width: calc(1870rem/16);
        margin: 0 auto;
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }

}

.navbar>.navbar-container{
    @media screen and (min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between
    }

    @media screen and (max-width: 767px){
       width: 100%;
    }
}


.navbar>.navbar-container>.row{
    @media screen and (max-width: 767px){
       margin: 0;
    }
}

.navbar-main{
    height: calc(54rem/16);
    position: relative;
    transition: all .3s ease-in-out;
    background-color: rgba(255,255,255,0.95);
    z-index: 12;
    @media screen and (min-width: 768px){
        height: calc(74rem/16);
        padding: 0;
    }
}
.navbar-main.is-open{
    transition: none;
}

/*
.navbar-parent.is-affix .navbar-main{
    background-color: rgba(255,255,255,0.82);
}*/

/*.navbar-main:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 100%);
    opacity: 0.8;
    height: calc(75rem/16);
    pointer-events: none;
    !*transition: all 250ms ease;*!

    @media screen and (min-width: 768px){
        height: calc(130rem/16);
        opacity: 0.9;
        visibility: visible;
        transition: opacity 200ms ease, visibility 100ms ease;
    }
}*/

/*
.navbar-white .navbar-main:after{
    @media screen and (min-width: 768px){
        background: linear-gradient(180deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
    }
}
.navbar-white.is-affix .navbar-main:after{
    background: transparent;
}
*/

/*
.navbar-main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: calc(56rem/16);
    transform: translateY(-100%);
    
    @media screen and (min-width: 768px){
        opacity: 0.95;
    }
}
*/

.navbar-white .navbar-main{
    z-index: 100;
    background-color: transparent;
}

.navbar-white.is-affix .navbar-main{
    background-color: #fff;
}
/*
.navbar-white .navbar-main:before{
    @media screen and (min-width: 768px){
       opacity: 0;
    }
}*/

.navbar-brand{

    margin-top: calc(-20rem/16);

    @media screen and (max-width: 767px){
       margin-right: 0;
    }
}

.navbar-simple .navbar-brand{
    @media screen and (max-width: 767px){
       margin-right: calc(15rem/16);
    }
}

.navbar-white.is-affix .navbar-brand{
    @media screen and (min-width: 768px){
       margin-top: calc(-9rem/16);
    }
}

.navbar-brand__img{
    height: calc(48rem/16);
    transition: height 250ms ease;
    @media screen and (min-width: 768px){
       height: calc(54rem/16);
    }

    @media screen and (min-width: 1200px){
        height: calc(72rem/16);

    }
}

.navbar-white.is-affix .navbar-brand__img{
    @media screen and (min-width: 768px){
       height: calc(66rem/16);
    }
}

.nav-link-main.nav-link{
    font-family: var(--font-default-bold);
    font-size: calc(25rem/16);
    padding: calc(15rem/16) calc(100rem/16) calc(15rem/16) calc(25rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
        padding: 0 calc(10rem/16);
        color: var(--color-default);
    }

    @media screen and (min-width: 1100px){
        white-space: nowrap;
    }

    @media screen and (min-width: 1200px){
        font-size: calc(19rem/16);
        padding: 0 calc(20rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(21rem/16);
    }
    
    @media screen and (max-width: 767px){
       line-height: 1.35;
        position: relative;
    }
}

.nav-link-main.nav-link:after{
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        height: 4px;
        width: 100px;
        background-color: green;
        bottom: 2px;
        left: 27px;
        opacity: 0;
    }
}

.nav-item-main.is-open .nav-link-main.nav-link:after{
    @media screen and (max-width: 767px){
        opacity: 1;
    }
}

.nav-link-main__toggle{
    @media screen and (max-width: 767px){
        color: var(--color-primary);
        position: absolute;
        right: calc(25rem/16);
        top: calc(30rem/16);
        font-size: calc(11rem/16);
    }
}

.nav-item-main.is-open .nav-link-main__toggle:before{
    transform: rotate(180deg);
    display: inline-block;
}

.nav-item-main{
    @media screen and (min-width: 768px){
       height: calc(64rem/16);
        display: flex;
        align-items: center;
        cursor: default;
    }
}

.navbar-white .nav-link-main{
    @media screen and (min-width: 768px){
        color: #fff;
        transition: all 150ms ease;
        transition-delay: 150ms;
    }

    color: var(--color-secondary);

}

.navbar-white.is-affix .nav-link-main{
    @media screen and (min-width: 768px){
       color: var(--color-default);
    }
}

.navbar-white .navbar-main.is-open .nav-link-main{
    color: var(--color-secondary);
}

.nav-link-main:after{
    @media screen and (min-width: 768px){
        content: '';
        height: calc(3rem/16);
        left: 0;
        right: 0;
        background-color: var(--color-primary);
        display: block;
        transform: scaleX(0);
        transition: all 250ms ease;
    }

}

.nav-item-main:hover .nav-link-main:after{
    @media screen and (min-width: 768px){
        transform: scaleX(1);
    }
}

.navbar-main .btn-acco{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(15rem/16);
        padding: calc(6rem/16) calc(20rem/16);
    }
}

.navbar-white .navbar-main .btn-acco{
    border: 1px solid #fff;
}

.navbar-parent button:focus{
    outline: none;
    box-shadow: none;
}


.navbar-simple .navbar-brand{
    margin-top: 0;
    
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

.navbar-simple .navbar-brand__img{
    height: calc(36rem/16);
    @media screen and (min-width: 768px){
        height: calc(55rem/16);
    }
}

@media screen and (max-width: 767px){
    .navbar-collapse{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(52rem/16);
        background-color: #fff;
        color: var(--color-default);
        transform: translateX(110%);
        transition: transform .25s ease;
        z-index: 20;
        padding-top: calc(74rem/16);

        display: flex;
        align-items: flex-start;
        flex-direction: column;

    }

    .nav-open .navbar-collapse{
        transform: translateX(0);
    }

    .navbar-collapse__inner{
        overflow-y: auto;
        padding-bottom: calc(50rem/16);
    }


    .navbar-main .navbar-nav{
        overflow-y: auto;
    }

    .navbar-main__mobile-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 50;
    }

    .navbar-collapse__bottom{
        width: 100%;
        padding: calc(5rem/16) calc(25rem/16) calc(25rem/16);
    }

    .navbar-toggler{
        display: inline-block;
        position: relative;
        width: calc(30rem/16);
        height: calc(24rem/16);
        cursor: pointer;
        padding: 0;
    }
    .navbar-toggler__line{
        display: block;
        position: relative;
        height: 4px;
        background: url(/static/img/line-dark.svg);
        background-size: 100% 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 2px;
        transition: background .2s linear .2s;
    }

    .navbar-toggler__line:before,
    .navbar-toggler__line:after{
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/static/img/line-dark.svg);
        background-size: 100% 100%;
        border-radius: 2px;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        transition: top .2s linear .2s,transform .2s linear,background .2s linear;
    }
    
    .navbar-toggler__line:after{
        top: calc(8rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__line:before,
    .navbar-toggler.is-open .navbar-toggler__line:after{
        top: 0;
        transform: rotate(-45deg);
        background: url(/static/img/line-dark.svg);
        background-size: 100% 100%;
        transition: top .2s linear,transform .2s linear .2s,background .2s linear;
    }

    .navbar-toggler.is-open .navbar-toggler__line:after{
        transform: rotate(45deg);
    }

    .navbar-toggler.is-open .navbar-toggler__line{
        background: transparent;
        transition: background .2s linear;
    }
    
    .navbar-search{
        font-size: calc(20rem/16);
        display: flex;
    }

    .navbar-search .icon{
        width: auto;
    }
}

.navbar-intranet .navbar-main{
    @media screen and (max-width: 767px){
       padding: calc(2rem/16) calc(5rem/16);
    }
}

.navbar-intranet .navbar-brand{
    margin-top: 0;
    @media screen and (max-width: 767px){
       margin-left: calc(-48rem/16);
    }
}

.navbar-intranet .navbar-brand__img {
    height: calc(40rem/16);
    @media screen and (min-width: 768px) {
        height: 60px;
    }
}

.navbar-intranet .navbar-dropdown-item__circle{
    @media screen and (max-width: 767px){
       width: calc(25rem/16);
       height: calc(25rem/16);
        min-width: auto;
    }
}
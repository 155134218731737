.anchor-nav{
    padding-top: calc(15rem/16);
    padding-bottom: calc(15rem/16);
    z-index: 12;
}
.anchor-nav.is-affix{
    padding-top: 0;
    padding-bottom: 0;
}

.anchor-nav__list{
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.anchor-nav__list .list-inline-item:not(:last-child){
    margin-right: 0;
}

.anchor-nav__item{
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    padding: 0 calc(5rem/16);
    white-space: nowrap;
    @media screen and (min-width: 1400px){
        font-size: calc(19rem/16);
        padding: 0 calc(10rem/16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(17rem/16);

    }
}

.anchor-nav__item .nav-link{
    padding: calc(20rem/16) calc(10rem/16);

    @media screen and (min-width: 1500px){
        padding: calc(20rem/16);
    }


}

.anchor-nav__item .nav-link:hover{
    color: var(--color-primary);
}

.anchor-nav__title{
    position: relative;
    padding-bottom: calc(5rem/16);
}

.anchor-nav__title:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(3rem/16);
    background-color: var(--color-primary);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 250ms ease;
}

.nav-link.active .anchor-nav__title:after{
    transform: scaleX(1);
}
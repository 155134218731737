.accommodation-filter{
    @media screen and (max-width: 767px){
       padding: calc(10rem/16);
    }
}

.accommodation-filter__form-group{
    position: relative;
    width: 100%;
}

.accommodation-filter-result-bar__right{
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px){
       font-size: calc(15rem/16);
        flex-direction: column;
    }
}

.accommodation-filter-result-bar__right .select--simple .form-control{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        padding-right: calc(15rem/16);
    }
}

.accommodation-filter-result-bar__right .select.select--simple:before {
    right: calc(2rem/16);
    font-size: calc(5rem/16);
}

.accommodation-filter-request-bar{
    position: relative;
    z-index: 3;
    @media screen and (max-width: 767px){
       font-size: calc(15rem/16);
    }
}

.accommodation-filter__btn-group .btn+.btn{
    margin-left: calc(15rem/16);
}

.accommodation-filter__quickfilter{
    padding: calc(3rem/16) calc(5rem/16);
    background-color: #fff;
    border: none;
    border-radius: calc(8rem/16);
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    text-align: left;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        padding: calc(5rem/16) calc(8rem/16);
    }
}

.accommodation-filter__quickfilter>span{
    pointer-events: none;
}

.accommodation-filter .quickfinder__form-control{
    @media screen and (max-width: 767px){
        border: none;
    }
}

.accommodation-filter__datepicker-from .quickfinder__form-control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.accommodation-filter__datepicker-to .quickfinder__form-control{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.accommodation-filter__info-text{
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
    }
}

.accommodation-filter__checkbox-list .list-inline-item:not(:last-child){
    margin-right: calc(20rem/16);
}

.accommodation-filter__btn{
    padding: calc(8rem/16) calc(14rem/16);

    @media screen and (min-width: 768px){
       height: calc(60rem/16);
        white-space: nowrap;
        padding: calc(10rem/16) calc(18rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(18rem/16);
    }

    @media screen and  (min-width: 1200px) {
        padding: calc(5rem/16);
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        font-size: calc(16rem/16);
    }
}

.accommodation-filter__btn.disabled{
    pointer-events: none;
    cursor: default;
}

.accommodation-filter__col{
    position: relative;
    width: 100%;
    padding-right: .625rem;
    padding-left: .625rem;

    @media screen and (min-width: 768px){
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1200px){
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.accommodation-filter__col-btn {
    @media screen and (min-width: 768px){
        flex: 0 0 35%;
        max-width: 35%;
    }
    @media screen and (min-width: 1200px){
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1400px){
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.accommodation-filter__col-6-btn {
    @media screen and (min-width: 768px){
        flex: 0 0 35%;
        max-width: 35%;
    }
    @media screen and (min-width: 1200px){
        flex: 0 0 20%;
        max-width: 25%;
    }
}

.accommodation-filter__col-6{
    @media screen and (max-width: 768px){
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: calc(4rem/16);
        padding-left: calc(4rem/16);
    }
}

.accommodation-filter__close-filter .icon{
    font-size: calc(17rem/16);
    vertical-align: calc(-3rem/16);
    margin-right: calc(3rem/16);

    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        font-size: calc(15rem/16);
        vertical-align: calc(-2rem/16);
        margin-right: calc(2rem/16);
    }
}

.accommodation-filter__close-filter{
    display: block;
}

.collapsed .accommodation-filter__close-filter{
    display: none;
}

.accommodation-filter__show-filter{
    display: none;
}

.collapsed .accommodation-filter__show-filter{
    display: block;
}
.accommodation-filter__map{
    width: 100%;
    height: 60vh;
    background-color: var(--color-bg);
    @media screen and (min-width: 768px){
       height: calc(100vh - 120px);
    }
}

.accommodation-filter__map.is-fullscreen{
    @media screen and (max-width: 767px){
        height: 100vh;
        position: fixed!important; /* overwritee inline */
        top: 0;
        left: 0;
        z-index: 3000;
    }

}


.accommodation-filter .react-select-wrapper .react-select__value-container{
    @media screen and (max-width: 767px){
        margin-top: calc(13rem/16);
    }
}

.accommodation-filter .quickfinder__guest-dropdown .dropdown-menu{
    @media screen and (max-width: 767px){
       border: none;
    }
}

.accommodation-filter__map .leaflet-top{
    @media screen and (min-width: 768px){
        top: 0;
    }
}

.accommodation-filter__map .leaflet-right .leaflet-control{
    margin-right: calc(15rem/16);
}

.accommodation-filter__map .leaflet-bar a:first-child{
    border-top-right-radius: calc(8rem/16);
    border-top-left-radius: calc(8rem/16);
    border: none;
}

.accommodation-filter__map .leaflet-bar a:last-child{
    border-bottom-right-radius: calc(8rem/16);
    border-bottom-left-radius: calc(8rem/16);
    border: none;
}

.accommodation-filter__map .leaflet-bar{
    border: none;
}
.accommodation-overview__map-toggle{
    background-color: #fff;
    color: var(--color-primary);
    border-radius: calc(8rem/16);
    padding: calc(8rem/16) calc(15rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
    border: none;
    white-space: nowrap;
    
    position: absolute;
    z-index: 500;
    top: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(12rem/16) calc(25rem/16);
        font-size: calc(18rem/16);
        right: calc(15rem/16);
    }
    
    @media screen and (max-width: 767px){
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: calc(15rem/16);
    }

}

.accommodation-overview__map-toggle.is-fullscreen{
    z-index: 2100;
}

.accommodation-overview__map-toggle .icon{
    vertical-align: calc(-3rem/16);
    margin-left: calc(5rem/16);

    @media screen and (min-width: 768px){
        vertical-align: calc(-5rem/16);
    }
}

.accommodation-overview__map-toggle-shrink{
    display: none;
}

.is-fullscreen .accommodation-overview__map-toggle-shrink{
    display: inline-block;
}

.is-fullscreen .accommodation-overview__map-toggle-extend{
    display: none;
}

.accommodation-filter__redirect-overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    background-color: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.accommodation-filter__redirect-overlay-img{
    width: calc(200rem/16);
    height: auto;
    margin-bottom: calc(50rem/16);
    
    @media screen and (min-width: 768px){
        width: calc(400rem/16);
    }
}

.accommodation-filter__redirect-overlay-loading{
    margin-top: calc(30rem/16);
}
.contact-teaser{
    background-color: #fff;
    border-radius: 4px;
    color: var(--color-secondary);
    @media screen and (min-width: 768px){
        font-size: calc(17rem/16);
        height: 100%;
    }

    @media screen and (min-width: 1500px){
        font-size: calc(19rem/16);
    }
}

.contact-teaser__name{
    @media screen and (min-width: 768px){
        margin-bottom: calc(25rem/16);
        font-size: calc(23rem/16);
    }
    @media screen and (min-width: 1500px){
        font-size: calc(30rem/16);
    }
    font-family: var(--font-default-bold);
    font-size: calc(25rem/16);
    line-height: calc(34/30);
    margin-bottom: calc(20rem/16);
}

.contact-teaser__body{
    padding: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(14rem/16) calc(30rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1300px){
       font-size: calc(18rem/16);
    }
}

.contact-teaser img,
.contact-teaser .embed-responsive-cover{
    @media screen and (max-width: 767px){
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media screen and (min-width: 768px){
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}
.form-errors{
    position: absolute;
    bottom: 0;
    left: calc(20rem/16);
    font-size: calc(11rem/16);
    color: var(--color-danger);
}
.form-group.has-error{
    position: relative;
}
.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.form-error--checkbox .form-errors{
    bottom: -0.7rem;
}

.form-group.has-error .form-control{
    border: 2px solid var(--color-danger)!important;
}

.custom-checkbox .form-errors{
    bottom: calc(-10rem/16);
}
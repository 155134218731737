.react-select-wrapper{
    z-index: 100;
}

.react-select-wrapper .react-select__control{
    background-color: #fff;
    color: var(--color-secondary);

    height: 3.125rem;
    padding: .375rem .9375rem;

    @media screen and (min-width: 768px){
        height: calc(60rem/16);
        padding: calc(26rem/16) calc(20rem/16) calc(8rem/16);
    }

}

/*.react-select-wrapper .react-select__indicator-separator{
    display: none;
}

.react-select-wrapper .react-select__indicator{
    display: none;
}*/

.react-select-wrapper .react-select__placeholder,
.react-select-wrapper .react-select__single-value{
    color: var(--color-secondary);
    font-family: var(--font-default-bold);
    margin: 0;
}

.accommodation-filter .react-select-wrapper .react-select__placeholder{
    top: 0;
    transform: none;
}

.react-select-wrapper .react-select__value-container{
    padding: 0;
    font-size: calc(18rem/16);
    
    @media screen and (max-width: 767px){
       margin-top: calc(7rem/16);
    }
}

.react-select-wrapper .react-select__menu{
    background-color: #fff;
    color: var(--color-secondary);
}

.form-group--xl .react-select-wrapper .react-select__control{
    @media screen and (min-width: 768px){
        height: calc(89rem/16);
        font-size: calc(16rem/16);
        padding: calc(34rem/16) calc(12rem/16) calc(8rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(18rem/16);
        padding: calc(34rem/16) calc(25rem/16) calc(8rem/16);
    }
}

.form-group--xl .react-select-wrapper .react-select__control:hover{
    border: none;
}

.quickfinder .react-select-wrapper .react-select__control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    
    @media screen and (max-width: 767px){
        border: 1px solid var(--color-bg-dark);
    }
}

.react-select__floating-label{
    z-index: 101;
    transform: scale(0.75) translateY(-29px);

    @media screen and (min-width: 768px){
        transform: scale(0.75) translateY(-35px);
    }
}

.quickfinder .react-select__floating-label{
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
       left: calc(28rem/16);
    }
}

.accommodation-filter .react-select__floating-label{
    @media screen and (min-width: 768px){
       left: calc(21rem/16);
        transform: scale(.85) translateY(-29px);
    }
}

.react-select__input{
    font-family: var(--font-default-bold);
}

.react-select-wrapper .css-tlfecz-indicatorContainer,
.react-select-wrapper .css-1gtu0rj-indicatorContainer{
    padding: 0;
    position: absolute;
    right: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%);
}
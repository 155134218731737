.marker-cluster__count{
    position: absolute;
    top: calc(4rem/16);
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
}

.leaflet-container a{
    color: var(--color-primary);
}

.leaflet-container a.btn-primary{
    color: #fff;
}

.leaflet-map-infobox__title{
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    line-height: 1.1;
    
    margin-bottom: calc(5rem/16);
    display: inline-block;
}

.marker-cluster__img{
    width: calc(32rem/16);
    height: auto;
}

/*
.leaflet-control-container .leaflet-left{
    @media screen and (min-width: 768px){
        display: none;
    }
}
*/

.leaflet-map-infobox__link{
    font-size: calc(14rem/16);
}
.btn{
    font-family: var(--font-default-bold);
}

.btn-md{
    font-size: calc(17rem/16);
    padding: calc(5rem/16) calc(12rem/16);
}

.btn-lg{
    @media screen and (max-width: 767px){
       padding: calc(8rem/16) calc(15rem/16);
    }
}

.btn-outline-primary:hover{
    color: #fff;
}

.btn:focus{
    outline: 0;
}

.btn-icon{
    line-height: 1;
    padding: calc(11rem/16);
    border: none;
    background-color: transparent;
}

.btn__icon{
    vertical-align: calc(-3rem/16);
    margin-right: calc(5rem/16);
}

.btn-primary-light{
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
    color: #fff;
}

.btn.btn-primary:hover{
    background-color: var(--color-primary-dark);
}

.btn-underline{
    padding: calc(8rem/16) calc(3rem/16);
    background-color: transparent;
    border: none;
    position: relative;
    font-size: calc(12rem/16);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    color: var(--color-primary);
}

.btn-underline:after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: url(/static/img/line-green.svg) no-repeat 50%;
    background-size: 100% 100%;
}

.btn-uab,
.is-uab .btn{
    background-color: var(--color-uab);
    color: #fff;
}

.btn-pzg,
.is-pzg .btn{
    background-color: var(--color-pzg);
    color: #fff;
}

.btn-uab:hover,
.btn-pzg:hover,
.is-uab .btn,
.is-pzg .btn{
    color: #fff;
}

.btn-wishlist:hover .icon:before,
.btn-wishlist.is-active .icon:before{
    content: var(--icon-heart);
}

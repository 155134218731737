.asset-video{
    cursor: pointer;
}

.asset-video__play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    width: calc(100rem/16);
    height: calc(100rem/16);
    background-color: var(--color-primary);
    color: #fff;
    font-size: calc(30rem/16);
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    border: none;

    transition: all .25s ease;
}

.asset-video:hover .asset-video__play{
    box-shadow: 0 0 13px 1px hsla(0,0%,100%,.65);
}

.is-started .asset-video__play{
    display: none;
}
.accommodation-offer-teaser{
    background-color: #fff;
    font-size: calc(17rem/16);
    border-radius: 4px;
}

.accommodation-offer-teaser__img {
    position: relative;
}

.accommodation-offer-teaser__img img{
    border-top-left-radius: 4px;
    
    @media screen and (max-width: 767px){
       border-top-right-radius: 4px;
    }
}

.accommodation-offer-teaser__img .btn-panorama {
    position: absolute;
    right: calc(25rem/16);
    top: calc(25rem/16);
    z-index: 1;
    @media (min-width: 768px) and (max-width: 1199px) {
        font-size: 14px;
        padding: 5px 10px;
        gap: 10px;
    }
    @media (min-width: 1200px) {
        font-size: 18px;
    }
}

.accommodation-offer-teaser__img .btn-panorama .icon {
    font-size: 28px;
}

.accommodation-offer-teaser .lightbox-img__dynamic-overlay{
    font-size: calc(20rem/16);
    display: flex;
    align-items: flex-end;
    padding: calc(15rem/16);
    justify-content: center;
}
.accommodation-offer-teaser .lightbox-img__dynamic-overlay:before{
    background: linear-gradient(180deg, rgba(31,31,29,0) 0%, rgba(31,31,29,0.8) 100%);

}

.accommodation-offer-teaser__body{
    padding: calc(25rem/16) calc(30rem/16);
}

.accommodation-offer-teaser__hl{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
}

.accommodation-offer-teaser__availability{
    padding: calc(25rem/16);
    
    @media screen and (max-width: 767px){
       padding-top: 0;
    }
}

.accommodation-offer-teaser__bottom{
    padding: calc(25rem/16);

    border-top: 1px solid var(--color-light-grey);
}

.accommodation-offer-teaser__fare{
    font-size: calc(20rem/16);
}

.accommodation-offer-teaser__price{
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
}

.accommodation-offer-teaser__price-info{
    font-size: calc(15rem/16);
}

.accommodation-offer-teaser__periods ul{
    font-size: calc(16rem/16);
}
.accommodation-offer-teaser__periods ul>li:not(:last-child){
    margin-bottom: calc(3rem/16);
}

.accommodation-offer-teaser__btns .btn{
    @media screen and (max-width: 767px){
       font-size: calc(17rem/16);
        padding: calc(6rem/16) calc(17rem/16);
    }
}

/* acco availability */

.acco-availability{
    text-align: center;
    font-size: calc(17rem/16);
}

.acco-availability-item__weekday{
    background-color: var(--color-primary);
    color: #fff;
    padding: calc(5rem/16) 0;
    width: calc(40rem/16);

    @media screen and (min-width: 1200px){
       width: 100%;
    }

 /*   @media screen and (min-width: 1200px){
        width: calc(36rem/16);
    }

    @media screen and (min-width: 1320px){
        width: calc(40rem/16);
    }*/
}

.not-available .acco-availability-item__weekday{
    background-color: var(--color-text-muted);
}

.acco-availability-item__item:hover .acco-availability-item__weekday {
    opacity: 0.9;
}

.acco-availability-item__day{
    background-color: #fff;
    font-family: var(--font-default-bold);
    padding: calc(8rem/16) 0;

    @media screen and (min-width: 1200px){
        padding: calc(8rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
       width: 100%;
        font-size: calc(14rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(15rem/16);
    }
}
.not-available .acco-availability-item__day{
    color: var(--color-text-muted);
}

.acco-availability ul{
    font-size: 0;
}

.acco-availability-item{
    font-size: calc(17rem/16);
    position: relative;
}

.acco-availability-item:not(:last-child){
    margin-right: 0;
}

.acco-availability-item:first-child .acco-availability-item__weekday{
    border-top-left-radius: 8px;
}

.acco-availability-item:first-child .acco-availability-item__day{
    border-bottom-left-radius: 8px;
}

.acco-availability-item:last-child .acco-availability-item__weekday{
    border-top-right-radius: 8px;
}

.acco-availability-item:last-child .acco-availability-item__day{
    border-bottom-right-radius: 8px;
}

.acco-availability-item__weekday,
.acco-availability-item__day{
    position: relative;
}

.acco-availability-item:not(:last-child) .acco-availability-item__weekday:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: calc(1rem/16);
    background-color: #fff;
    opacity: 0.3;
}

.acco-availability-item__day{
    border-bottom: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
}

.acco-availability-item:first-child .acco-availability-item__day{
    border-left: 1px solid #f5f5f5;
}

.not-available-sign{
    width: calc(22rem/16);
    height: calc(22rem/16);
    border-radius: 50%;
    border: 1px solid var(--color-text-muted);
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translateY(-50%);
    display: none;

    @media screen and (min-width: 1200px){
        width: calc(26rem/16);
        height: calc(26rem/16);
        left: calc(6rem/16);
    }
}

.not-available-sign:before{
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    height: 1px;
    background-color: var(--color-text-muted);
    transform: rotate(-45deg);

}

.not-available .not-available-sign{
    display: inline-block;
}

.acco-availability__info{
    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
    }
}

.acco-availability__info .icon{
    font-size: calc(19rem/16);
}

.acco-availability__head{
    margin-top: calc(-15rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(-30rem/16);
    }
}
.acco-availability__head-info{
    min-height: calc(22rem/16);
}
.acco-availability__month {
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    display: block;
    margin-top: calc(-10rem/16);
    position: relative;
    top: calc(-15rem/16);

}

.swiper-availability .swiper-button--prev {
    @media screen and (min-width: 768px){
        margin-right: calc(200rem/16);
    }
    
    margin-right: calc(180rem/16);
}
.enquiry-overlay{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3001;
    background-color: var(--color-light-grey);
    transform: translateX(100%);
    transition: transform 250ms ease;
    width: calc(100% - 31px);

    @media screen and (min-width: 768px){
        width: calc(600rem/16);
    }
}

.enquiry-overlay.is-open,
.enquiry-active .enquiry-overlay.is-open{
    transform: translateX(0);
}

.enquiry-overlay__overlay{
    padding: calc(25rem/16);
    height: 100%;
    overflow-y: auto;
    @media screen and (min-width: 768px){
        padding: calc(50rem/16);
    }
}

.enquiry-overlay__btn{
    position: absolute;
    right: 100%;
    top: 50%;
    transform: rotate(-90deg) translateY(-100%) translateX(50%);
    transform-origin: right top;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        padding: calc(3rem/16) calc(9rem/16);
    }
}

.enquiry-overlay__btn-icon{
    font-size: calc(11rem/16);
    margin-left: calc(9rem/16);
    vertical-align: calc(-2rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }
}

.is-open .enquiry-overlay__btn-icon{
    transform: rotate(45deg);
}

.blink{
    animation: blinkingBackground 1.8s forwards;
    animation-iteration-count: 1;
}

@keyframes blinkingBackground {
    0% {
        background-color: var(--color-primary);
    }
    25% {
        background-color: #074327;
    }
    50% {
        background-color: var(--color-primary);
    }
    75% {
        background-color: #074327;
    }
    100% {
        background-color: var(--color-primary);
    }
}

.enquiry-overlay__subtitle{
    font-family: var(--font-default-bold);
}

.enquiry-overlay__small{
    font-size: calc(16rem/16);
}

.enquiry-fallback{
    background-color: #fff;
    padding: calc(15rem/16);
    border-radius: calc(5rem/16);
}

.pool-enquiry__remove{
    display: none;
}

.pool-enquiry__btn.active .pool-enquiry__remove{
    display: block;
}

.pool-enquiry__btn.active .pool-enquiry__add{
    display: none;
}


/** enquiry acco */

.enquiry-active .pool-enquiry__start{
    display: none;
}

.pool-enquiry__end{
    display: none;
}

.enquiry-active .pool-enquiry__end{
    display: block;
}

.pool-enquiry__end .icon{
    vertical-align: calc(-4rem/16);
}

.enquiry-overlay--acco{
    transform: translateX(109%);
    @media screen and (min-width: 768px){
       width: 40vw;
    }
}

.enquiry-active .enquiry-overlay--acco{
    transform: translateX(100%);
}

.pool-enquiry-max-items-notification{
    position: fixed;
    bottom: calc(5rem/16);
    right: calc(10rem/16);
    z-index: 99999;
}

.enquiry-overlay__aa4y{
    display: none;
}

.enquiry-overlay__aa4y.is-visible{
    display: block;
}

.floating-label {
    position: absolute;
    left: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    color: var(--color-text-muted);
    font-size: calc(16rem/16);
    white-space: nowrap;

    @media screen and (min-width: 768px){
        left: calc(20rem/16);
    }
}

.select.no-floating .floating-label{
    padding: calc(10rem/16);
    background-color: #fff;
    font-family: var(--font-default-bold);
    color: var(--color-default);
    width: 80%;
    
    @media screen and (min-width: 768px){
       font-size: calc(18rem/16);
    }
}

.floating-label.floating-label--textarea{
    top: calc(7rem/16);
    transform: none;
}


.form-group__white .floating-label{
    color: #fff;
}

.form-control:focus ~ .floating-label,
select:not(.no-floating) ~ .floating-label,
.select:not(.no-floating) .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label,
.form-group:focus .floating-label {
    transform: translateY(calc(-23rem / 16)) scale(.75);

  /*  @media screen and (min-width: 768px){
        transform: translateY(calc(-23rem / 16)) scale(.75);
    }*/
}

.form-group.has-value .floating-label--textarea,
.form-group:focus .floating-label--textarea {
    transform: translateY(0) scale(.75);
   /* @media screen and (min-width: 768px){
        transform: translateY(0) scale(.85);
    }*/
}

.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-23rem / 16)) scale(.75);
   /* @media screen and (min-width: 768px){
        transform: translateY(calc(-23rem / 16)) scale(.85);
    }*/
}

/* Form group xl */

.form-group--xl .floating-label{
    left: calc(16rem/16);
    font-size: calc(17rem/16);

    @media screen and (min-width: 1400px){
        left: calc(25rem/16);
    }
    
    @media screen and (min-width: 768px){
        left: calc(12rem/16);
        font-size: calc(18rem/16);
    }
}

.form-group--xl textarea.form-control.has-value ~ .floating-label,
.form-group--xl textarea.form-control:focus ~ .floating-label{
    transform: translateY(0) scale(.75);
}


.form-group--xl .form-control:focus ~ .floating-label,
.form-group--xl select ~ .floating-label,
.form-group--xl .form-control.has-value ~ .floating-label,
.form-group--xl.form-group.has-value .floating-label {
    transform: translateY(calc(-24rem / 16)) scale(.75);
}

.form-group--xl .form-control:-webkit-autofill ~ .form-control-label,
.form-group--xl .form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-24rem / 16)) scale(.75);
}
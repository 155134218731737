.slide {
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);
    background-color: var(--color-light-grey);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(80rem/16);
        padding-bottom: calc(50rem/16);
    }
}

.slide-white-bg{
    background-color: #fff;
}


.bg-decorator{
    position: relative;
}


.bg-decorator:after{
    content: "";
    display: block;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    height: 35px;
    background: transparent url(/static/img/backgrounds/decorator-bottom-beige.svg) no-repeat 100% 0;
    background-size: 110% auto;
    z-index: 1;
}

.slide-white-bg.bg-decorator:after,
.bg-white.bg-decorator:after{
    background: transparent url(/static/img/backgrounds/decorator-bottom.svg) no-repeat 100% 0;
    background-size: 110% auto;
}

.author-teaser{
    font-size: calc(17rem/16);
    text-align: center;
}

.author-teaser__img{
    width: calc(185rem/16);
    height: calc(185rem/16);
    border-radius: 50%;
    margin: 0 auto;
    border: 10px solid #fff;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
}

.author-teaser__img img{
    border-radius: 50%;
}

.author-teaser__title{
    font-size: calc(30rem/16);
    font-family: var(--font-default-bold);
    margin-top: calc(20rem/16);
    margin-bottom: calc(5rem/16);
}

.author-teaser__subtitle{
    color: var(--color-text-muted);
}

.author-teaser__link{
    color: var(--color-primary);
    font-family: var(--font-default-Fbold);
    padding-bottom: calc(5rem/16);
    position: relative;
    margin-top: calc(5rem/16);
    display: inline-block!important;
    width: fit-content;
}

.author-teaser__link:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(2rem/16);
    background-color: var(--color-primary);
}

/* Horizontal */

.author-teaser--horizontal{
    @media screen and (min-width: 768px){
        text-align: left;
    }
    padding: calc(40rem/16) 0;
}

.author-teaser--horizontal .author-teaser__img{
    @media screen and (min-width: 768px){
       width: calc(142rem/16);
       height: calc(142rem/16);
    }
}

.authors-list__item:not(:last-child) .author-teaser--horizontal{
    @media screen and (max-width: 767px){
        border-bottom: 2px dashed var(--color-grey);
    }
}

.modal-body{
    padding: calc(20rem/16) calc(30rem/16);
    
    @media screen and (min-width: 768px){
        padding: calc(90rem/16) calc(70rem/16);
    }
}

.modal-content{
    background-color: var(--color-bg);
}

.modal-dialog .close{
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(35rem/16);
    height: calc(35rem/16);
    background-color: var(--color-primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 0 6px;
    opacity: 1;
    font-size: calc(15rem/16);
    margin: 0;
}
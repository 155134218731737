.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive-md-cover{
        height: 100%;
    }

    .embed-responsive-md-cover .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

}

.embed-responsive-img-teaser-small:before {
    padding-top: 100%;
}

.embed-responsive-img-teaser-big:before {
    padding-top: 100%;
    @media screen and (min-width: 768px){
        padding-top: 50%;
    }
}

.embed-responsive-hero-magazin-detail-img:before{
    padding-top: 81.23711%;
}

.embed-responsive-img-gallery-big:before{
    padding-top: 76.8069%;
}

.embed-responsive-img-gallery-medium:before{
    padding-top: 57.5%;
}

.embed-responsive-img-gallery-small:before{
    padding-top: 100%;
}

.embed-responsive-leaflet-mobile:before{
    @media screen and (max-width: 767px){
        padding-top: 100%;
    }
}


.cse-teaser{
    background-color: #fff;
}

.cse-teaser__body{
    padding: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(10rem/16);
    }
}

.cse-teaser__hl{
    margin-bottom: calc(10rem/16);
    color: var(--color-primary);
}

.footer{
    @media screen and (max-width: 767px){
       text-align: center;
    }
}
.footer__main{
    background-color: var(--color-primary);
    color: #fff;
    font-size: calc(16rem/16);
    padding: calc(30rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(90rem/16) 0 calc(35rem/16);
    }
}

.footer--simple .footer__main{
    @media screen and (min-width: 768px){
       padding-top: calc(65rem/16);
    }
}

.footer__social-list>.list-inline-item:not(:last-child){
    margin-right: calc(20rem/16);
}
.footer__social-item{
    font-size: calc(18rem/16);
}

.footer__logo{
    margin-bottom: calc(20rem/16);
    
    @media screen and (min-width: 768px){
        max-width: calc(185rem/16);
        width: 100%;
        margin-bottom: calc(50rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(42rem/16);
    }
}

.footer--simple .footer__logo{
    height: calc(40rem/16);
    width: auto;

    @media screen and (min-width: 768px){
       max-width: none;
        height: calc(70rem/16);
    }
}
.footer__link-item{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    letter-spacing: calc(1rem/16);
}

a.footer__link-item:hover{
    text-decoration: underline;
}

.footer__link-list>li:not(:last-child){
    margin-bottom: calc(15rem/16);
}

.footer--simple .footer__link-list>li:not(:last-child){
    margin-right: calc(25rem/16);
}

.footer__link-item--hl{
    opacity: 0.8;
}

.footer__images-item {
    width: auto;
    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }

    @media screen and (max-width: 767px){
        display: block;
        text-align: center;
        margin: 0 auto;
        max-height: 48px;
        margin-top: calc(10rem/16);
    }
}

.footer__images-item.small-img{
    @media screen and (min-width: 768px){
       height: calc(50rem/16);
    }
}

.footer__images-item+.footer__images-item{
    margin-left: calc(20rem/16);
    display: inline-block;
}

.footer__bottom{
    background-color: #fff;
    padding: calc(20rem/16) 0;
}
.has-affix-bottom-bar .footer__bottom {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(80rem/16);
    }
}

.footer .input-group--email .form-group{
    @media screen and (max-width: 767px){
        flex: 1 1 auto;
        width: 1%;
    }
}

.footer label a{
    color: #fff;
    text-decoration: underline;
}
/* position of menu active */
.skd-widget.skd-widget .skd-widget-dropdown-menu {
    left: auto;
    right: -1rem;
    @media screen and (min-width: 768px) {
        left: 0;
        right: 0;
    }
}
.skd-widget.skd-widget .skd-widget-dropdown-menu-right {
    left: -1rem;
    right: 0;
    @media screen and (min-width: 768px) {
        left: 0;
        right: 0;
    }
}
.skd-offer-list-widget .skd-room-info {
    text-shadow: none!important;
}
.trustyou-rating__box {
    line-height: 1;
    font-family: var(--font-default-bold);
    color: var(--color-success);
    position: relative;
    z-index: 2;
}
.trustyou-rating__score {
    position: relative;
    display: inline-block;
    padding: calc(5rem/16) calc(6rem/16);
    background: var(--color-success);
    color: #ffffff;
    margin-right: calc(2rem/16);
    margin-bottom: calc(5rem/16);
    font-size: calc(14rem/16);
    white-space: nowrap;
    @media screen and (min-width: 1200px) and (max-width: 1300px){
       font-size: calc(12rem/16);
    }
}
.trustyou-rating__score:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 0;
    border-color: var(--color-success) transparent transparent transparent;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -1px;
}
.trustyou-rating__count {
    margin-left: .25rem;
    font-family: var(--font-default);
}


.trustyou-rating__logo {
    width: calc(65rem/16);
    height: calc(10rem/16);
    background-image: url(/static/img/rating/trustyou-logo.png);
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and (min-width: 1580px){
        width: calc(85rem/16);
    }

}

/* Flower Rating */

.flower-rating{
    white-space: nowrap;
}

.flower-rating .list-inline-item:not(:last-child){
    margin: 0;
}

.flower-rating__item{
    background: transparent url(/static/img/rating/flower.svg) no-repeat 0 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    height: calc(18rem/16);
    width: calc(18rem/16);
    background-size: 100% 100%;
    margin: 0;

    @media screen and (min-width: 1580px){
        margin: 0 calc(2rem/16);
        height: calc(22rem/16);
        width: calc(22rem/16);
    }
}


/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }


/* Background Color Helper */
.bg-light-grey{
    background-color: var(--color-light-grey);
}

.bg-white{
    background-color: #fff;
}

.col-xxl{
    @media screen and (min-width: 1600px){
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}


/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-weight: inherit;
    cursor: pointer;
    font-family: var(--font-default);
}

.btn-no-styling.font-bold{
    font-family: var(--font-default-bold);
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.text-underlined{
    text-decoration: underline;
}


.link-primary{
    display: inline-block;
    font-family: var(--font-default-bold);
    font-size: calc(17rem/16);
    padding-bottom: calc(2rem/16);
    color: var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}

.hr-dashed{
    border-top: 2px dashed var(--color-bg-dark);
    margin: calc(25rem/16) 0;
}

.multicolumn-3{
    @media screen and (min-width: 768px){
        column-count: 3;
    }
}

.multicolumn-2{
    @media screen and (min-width: 768px){
        column-count: 2;
    }
}

.multicolumn__avoid-break {
    break-inside: avoid;
}

.w-100{
    width: 100%;
}

.border-right-md{
    @media screen and (min-width: 768px){
       border-right: 1px solid var(--color-bg-dark);
    }
}

hr{
    border-color: #EFE9DF;
}

.hide{
    display: none;
}

/*
section {
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
}
*/


.col-xxl-2 {
    @media screen and (min-width: 1650px){
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
}


.vertical-line-40 {
    height: calc(1rem/16);
    width: 40%;
    background-color: black;
    opacity: 0.4;
}
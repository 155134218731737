.cookie-bar{
    z-index: 3000;
}

.cookie-modal__close:before {
    content: var(--icon-close);
    font-family: iconfont;
}

.cookie-modal__body.modal-body{
    padding: calc(30rem/16) calc(20rem/16);
}

.cookie-bar__btn{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}
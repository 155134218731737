.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item ,
.vertical-gutter--1 > [class^="col"],
.vertical-gutter--1 > [class*=" col"]{
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item,
.vertical-gutter--2 > [class^="col"],
.vertical-gutter--2 > [class*=" col"] {
    margin-top: .5rem;
}

@media screen and (max-width: 767px){
    .vertical-gutter-xs--2 {
        margin-top: -.5rem;
    }
    .vertical-gutter-xs--2 > .vertical-gutter__item,
    .vertical-gutter-xs--2 > [class^="col"],
    .vertical-gutter-xs--2 > [class*=" col"] {
        margin-top: .5rem;
    }
}

.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item,
.vertical-gutter--3 > [class^="col"],
.vertical-gutter--3 > [class*=" col"] {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item,
.vertical-gutter--4 > [class^="col"],
.vertical-gutter--4 > [class*=" col"] {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item,
.vertical-gutter--5 > [class^="col"],
.vertical-gutter--5 > [class*=" col"] {
    margin-top: 3rem;
}
.vertical-gutter--6 {
    margin-top: -4.5rem;
}
.vertical-gutter--6 > .vertical-gutter__item,
.vertical-gutter--6 > [class^="col"],
.vertical-gutter--6 > [class*=" col"] {
    margin-top: 4.5rem;
}


.vertical-gutter--20 {
    margin-top: calc(-20rem/16);
}
.vertical-gutter--20 > .vertical-gutter__item,
.vertical-gutter--20 > [class^="col"],
.vertical-gutter--20 > [class*=" col"]{
    margin-top: calc(20rem/16)
}

@media screen and (min-width: 768px){
    .vertical-gutter--md-65 {
        margin-top: calc(-65rem/16);
    }
    .vertical-gutter--md-65 > .vertical-gutter__item,
    .vertical-gutter--md-65 > [class^="col"],
    .vertical-gutter--md-65 > [class*=" col"] {
        margin-top: calc(65rem/16)
    }

}

@media screen and (min-width: 768px){
    .vertical-gutter--md-35 {
        margin-top: calc(-35rem/16);
    }
    .vertical-gutter--md-35 > .vertical-gutter__item,
    .vertical-gutter--md-35 > [class^="col"],
    .vertical-gutter--md-35 > [class*=" col"] {
        margin-top: calc(35rem/16)
    }

}

.switch {
    position: relative;
    display: inline-block;
    width: calc(43rem/ 16);
    height: calc(25rem / 16);
    vertical-align: middle;
}
.switch__toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .4s;
    border-radius: calc(1000rem/16);
    background-color: var(--color-primary);
    opacity: 0.5;
}
.switch__toggle-handle {
    position: absolute;
    content: "";
    height: calc(15rem / 16);
    width: calc(15rem / 16);
    left: calc(4rem/16);
    bottom: calc(5rem/16);
    background-color: #fff;
    transition: .25s;
    border-radius: 50%;
}

.switch__text{
    font-size: calc(19rem/16);
    vertical-align: calc(-2rem/16);
}

input:checked + .switch__toggle {
    opacity: 1;
}

input:checked + .switch__toggle .switch__toggle-handle {
    transform: translateX(calc(19rem / 16));
    background-color: #ffffff;
}

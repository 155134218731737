html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    background-color: var(--color-bg);
}

body.is-white{
    background-color: #fff;
}

body.nav-open{
    @media screen and (max-width: 767px){
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1640rem/16); /* 1600px container breite + 40px padding */
    width: 100%;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    
    @media screen and (max-width: 767px){
       padding-right: calc(25rem/16);
       padding-left: calc(25rem/16);
    }
}

.container-narrow{
    max-width: calc(990rem/16); /* 950px container breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}


.container-small{
    max-width: calc(1220rem/16); /* 1180px container breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.container-medium{
    width: 100%;
    max-width: calc(1310rem/16); /* 1270px container breite + 40px padding */
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.container-large{
    width: 100%;
    max-width: calc(1870rem/16);
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.container-form{
    width: 100%;
    max-width: calc(505rem/16);/* 465px container breite + 40px padding */
    margin: 0 auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.container-outer{
    overflow: hidden;
}

.main-content--padding-top{
    padding-top: calc(110rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(175rem/16);
    }
}

.main-content--padding-top-small{

    @media screen and (min-width: 768px){
        padding-top: calc(80rem/16);
    }
    padding-top: calc(50rem/16);
}

.row-md{
    @media screen and (min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        margin-right: calc(-12rem/16);
        margin-left: calc(-12rem/16);
    }
}

@media screen and (min-width: 768px) {
    .container__break-right-half-md {
        width: calc(50vw - calc(var(--row-gutter) / 2));
    }

}

@media screen and (min-width: 768px) {
    .container__break-left-half-md {
        width: calc(50vw - calc(var(--row-gutter) / 2));
        margin-left: 100%;
        transform: translateX(-100%);
    }
}
.accordion .card{
    background-color: transparent;
    border: none;
}

.accordion .card-header{
    padding: 0;
    background-color: transparent;
    border: none;
}
.accordion__header-link{
    display: block;
    padding: calc(15rem/16);
    position: relative;
}

.accordion__header-title{
    font-size: calc(36rem/16);
    line-height: calc(54/48);
    font-family: var(--font-amorie);
    background-color: var(--color-bg);
    color: var(--color-primary);
    padding-right: calc(30rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px){
        font-size: calc(48rem/16);
    }
}

.slide-white-bg .accordion__header-title{
    background-color: #fff;
}

.accordion__header-link:after {
    content: "";
    position: absolute;
    left: calc(20rem/16);
    top: 49%;
    width: calc(100% - 90px);
    height: 0;
    border-top: 2px dashed var(--color-bg-dark);
    border-left: 2px dashed transparent;
    border-right: 2px dashed transparent;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 0;

    @media screen and (min-width: 768px){
        top: 50%;
    }
}

.accordion__toggle-icon{
    color: var(--color-primary);
    background-color: var(--color-bg);
    transform: rotate(180deg);
    position: relative;
    z-index: 1;
}

.collapsed .accordion__toggle-icon{
    transform: rotate(0);
}

.slide-white-bg .accordion__toggle-icon{
    background-color: #fff;
}

/* Accordion Primary */

.accordion--primary{
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
}

.accordion--primary .card-header{
    background-color: var(--color-primary);
    color: #fff;
}

.accordion--primary__header-title{
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.accordion--primary__header-link{
    font-family: var(--font-default-bold);
    padding: calc(11rem/16) calc(20rem/16);
    display: block;
}

.accordion--primary__toggle-icon:before{
    display: inline-block;
    transform: rotate(45deg);
}

.collapsed .accordion--primary__toggle-icon:before{
    transform: rotate(0deg);
}
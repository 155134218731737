
/* width */
.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: var(--color-light-grey);
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-grey);
    border-radius: 1000px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c5bfb7;
}
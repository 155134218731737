

.quickfinder .quickfinder__guest-dropdown.is-open .dropdown-toggle{
    border-bottom: 0;
}

.quickfinder.quickfinder--acco .dropdown-toggle,
.quickfinder--acco .quickfinder__guest-dropdown .dropdown-menu{
    @media screen and (max-width: 767px){
        border: none;
    }
}


.quickfinder__guest-dropdown .dropdown-menu{
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    margin: 0;
    padding: 20px;

    @media screen and (min-width: 768px){
        width: calc(600rem/16);
        top: calc(100% + 8px);
    }

    @media screen and (max-width: 767px){
        position: relative;
        display: none;
        box-shadow: none;
        border: 1px solid var(--color-bg-dark);
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        transform: none;
        top: calc(8rem/16);
        left: 0;
    }
}

.quickfinder__guest-dropdown-inner{
    @media screen and (min-width: 768px){
        max-height: calc(500rem/16);
        overflow-y: auto;
        padding: 0 calc(10rem/16);
    }
}


.quickfinder__guest-dropdown.is-open .dropdown-menu{
    @media screen and (max-width: 767px){
        display: block;
    }
}

.quickfinder__guest-dropdown.dropup .dropdown-toggle:after{
    display: none;
}


.quickfinder--acco .quickfinder__guest-dropdown .quickfinder__form-group-label{
    @media screen and (min-width: 768px){
        top: calc(18rem/16);
    }
}

.accommodation-filter .quickfinder__guest-dropdown .quickfinder__form-group-label{
    @media screen and (min-width: 768px){
        top: calc(6rem/16);
    }
}

.quickfinder__guest-dropdown.is-open .quickfinder__arrow{
    transform: rotate(180deg);
    display: inline-block;
}


.quickfinder__room+.quickfinder__room{
    border-top: 2px dashed var(--color-grey);
    padding-top: calc(18rem/16);
}



.quickfinder__add-room{
    font-size: calc(17rem/16);
    padding: calc(5rem/16) calc(11rem/16);
    display: flex;
    align-items: center;
}

.quickfinder__add-room .icon{
    font-size: calc(13rem/16);
    margin-right: calc(5rem/16);
}


.quickfinder__remove-room{
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid var(--color-danger);
    color: var(--color-danger);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: calc(5rem/16);
    font-size: calc(10rem/16);
}

.quickfinder__remove-room .icon{
    transform: rotate(45deg);
    vertical-align: calc(-1rem/16);
}

.quickfinder__room:first-child .quickfinder__remove-room{
    display: none;
}
.recipe-area__title{
    font-family: var(--font-amorie);
    color: var(--color-primary);
    margin-bottom: calc(25rem/16);
    font-size: calc(50rem/16);

    @media screen and (min-width: 768px){
       font-size: calc(60rem/16);
    }
}

.recipe-area__ingredients{
    border: 2px dashed var(--color-grey);
    padding: calc(34rem/16);
}
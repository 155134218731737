.filter-dropdown__button.form-control{
    text-align: left;
    padding: calc(5rem/16) calc(10rem/16);

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(20rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(40rem/16);
    }
}

.filter-dropdown__icon{
    font-size: calc(5rem/16);
    position: absolute;
    right: calc(9rem/16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        font-size: calc(7rem/16);
        right: calc(20rem/16);
    }
}

.filter-dropdown__label{
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
    }
}

.filter-dropdown__menu{
    width: 100%;
    
    @media screen and (max-width: 767px){
       padding: calc(15rem/16);
        font-size: calc(15rem/16);
    }
}

.filter-dropdown__menu:focus{
    outline: none;
}

.filter-dropdown__inner{
    overflow: auto;
    max-height: calc(210rem/16);

    @media screen and (min-width: 768px){
        max-height: calc(250rem/16);
    }
    
    @media screen and (max-width: 767px){
       padding-top: calc(7rem/16);
    }
}

.filter-dropdown__menu.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.filter-dropdown__checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.filter-dropdown__checkbox > label {
    width: 100%;
}
.filter-dropdown__checkbox__text {
    width: 100%;
    border-radius: calc(5rem/16);
    padding: calc(7rem/16) calc(13rem/16);
    border: 1px solid rgba(255, 255, 255, 0.52);
    display: block;
    position: relative;
}
.filter-dropdown__checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1.25em;
    height: 1.25em;
    margin-top: 0.12em;
    border-radius: calc(4rem/16);
}
.filter-dropdown__checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.5em;
    color: var(--color-primary);
    visibility: hidden;
}

.filter-dropdown__checkbox__input:checked ~ .filter-dropdown__checkbox__box:before {
    visibility: visible;
}
.filter-dropdown__checkbox__input:focus ~ .filter-dropdown__checkbox__box {
    border-color: var(--color-primary);
}

.filter-dropdown__counter-wrapper{
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }
}
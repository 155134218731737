.navbar-top{
    font-size: calc(19rem/16);
    height: calc(52rem/16);
    position: relative;
    z-index: 3002;
    display: flex;
    align-items: center;
    /*background-color: rgba(255,255,255,0.95);*/

    @media screen and (min-width: 768px){
        height: calc(56rem/16);
    }
    
    @media screen and (max-width: 767px){
       padding: calc(5rem/16) calc(24rem/16);
    }
}

.navbar-main:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(56rem/16);
    transform: translateY(-100%);
    background-color: #fff;
    opacity: 0.95;
}


.navbar-white .navbar-main:before{
    background-color: var(--color-bg);
    opacity: 1;
}

.navbar-white.is-affix .navbar-top{
    background-color: #fff;

}

/*.navbar-white.is-affix .navbar-main .navbar-brand__img {
    @media screen and (min-width: 1200px) {
        transform: scale(0.8);
    }
}*/

.navbar-white.is-affix .navbar-main:before {
    @media screen and (min-width: 1200px) {
        position: relative;
    }
}

.navbar-white .navbar-top:after{
    background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.8) 100%);
    opacity: 1;
}

.navbar-top>.navbar-container{
    @media screen and (min-width: 768px){
        display: flex;
        justify-content: space-between;
    }
    
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.navbar-right{
    @media screen and (max-width: 767px){
        padding: calc(13rem/16) calc(10rem/16) calc(8rem/16);
        border-top: 1px solid #e5e5e5;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffff;
        z-index: 10;
    }
}

.navbar-right__list{
    @media screen and (max-width: 767px){
       display: flex;
        justify-content: center;
    }
}

.navbar-right__list .list-inline-item{
    @media screen and (max-width: 767px){
       display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0;
    }
}

.navbar-right__item{
    display: flex;
    align-items: center;
    padding: 0;

    @media screen and (min-width: 768px){
       color: var(--color-default);
        line-height: 1;
    }
    
    @media screen and (max-width: 767px){
       flex-direction: column;
        font-size: calc(12rem/16);
        white-space: nowrap;
    }
}

.navbar-right__item .dropdown-toggle{
    @media screen and (max-width: 767px){
       display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.navbar-right__item .dropdown-toggle:after{
    display: none;
}

.navbar-right__item >.icon,
.navbar-right__item >.dropdown-toggle>.icon{
    font-size: calc(20rem/16);
    margin-top: calc(-2rem/16);
    @media screen and (min-width: 768px){
       width: calc(23rem/16);
        margin-right: calc(8rem/16);
        visibility: visible;
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(5rem/16);
    }
}

.navbar-right__item >.dropdown-toggle>.icon,
.navbar-right__lang>.icon{
    @media screen and (max-width: 767px){
       margin-bottom: calc(6rem/16);
    }
}

.navbar-right__item >.dropdown-toggle>.icon-user{
    vertical-align: calc(-1rem/16);
    font-size: calc(19rem/16);
    @media screen and (min-width: 768px){
       margin-right: calc(2rem/16);
    }
}

.navbar-right__item .icon-sun{
    font-size: calc(22rem/16);
}

.navbar-right__item >.icon.icon-book{
    font-size: calc(23rem/16);
    margin-top: calc(-3rem/16);
}

.navbar-right__item .dropdown-menu,
.dropdown--profil .dropdown-menu{
    @media screen and (max-width: 767px){
        top: auto;
        bottom: 100%;
        margin-bottom: calc(20rem/16);
        float: none;
        left: 50%;
        transform: translateX(-50%);
    }
}

.navbar-right__item.dropdown--arrow .dropdown-menu:before{
    @media screen and (max-width: 767px){
       top: auto;
        bottom: calc(-13rem/16);
    }
}

.navbar-right__item.dropdown--arrow .dropdown-menu:after{
    @media screen and (max-width: 767px){
       top: auto;
        bottom: 0;
    }
}

.navbar-right .list-inline-item:not(:last-child){
    margin-right: calc(20rem/16);

    @media screen and (min-width: 1200px){
        margin-right: calc(40rem/16);
    }
}
/*

.navbar-top__region-btn{
    @media screen and (min-width: 768px){
        margin-left: calc(69rem/16);

    }
}
*/

.navbar-top__region-btn .icon{
    color: var(--color-primary);
    font-size: calc(6rem/16);
    vertical-align: calc(1rem/16);
    @media screen and (max-width: 767px){
        width: auto;

    }
}

.navbar-top .dropdown-toggle:after{
    display: none;
}

.navbar-top .dropdown-menu{
    border-bottom-right-radius: calc(8rem/16);
    border-bottom-left-radius: calc(8rem/16);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    transform: translateX(-50%);
    left: 50%;
    margin-top: calc(20rem/16);

    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
    padding: calc(14rem/16) 0;
}

.navbar-top .dropdown-item{
    padding: calc(6rem/16) calc(28rem/16);
    font-size: calc(18rem/16);
    position: relative;
    z-index: 1;
}

.regions-list__item{
    font-family: var(--font-default-bold);
    font-size: calc(19rem/16);
}

.regions-list__item:hover{
    color: var(--color-primary);
}

.navbar-left{
    @media screen and (min-width: 768px){
       display: flex;
        justify-content: center;
        width: calc(192rem/16);
    }
    @media screen and (max-width: 767px){
       display: flex;
        justify-content: space-between;
    }
}

.navbar-dropdown-item{
    display: flex;
    align-items: center;
    font-size: calc(18rem/16);
    padding: calc(10rem/16) calc(25rem/16);

}

.navbar-dropdown-item:hover{
    background-color: #f8f9fa;
}

.navbar-dropdown-item__circle{
    width: calc(32rem/16);
    min-width: calc(32rem/16);
    height: calc(32rem/16);
    color: var(--color-secondary);
    background-color: var(--color-grey);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: calc(14rem/16);
    margin-right: calc(10rem/16);
    position: relative;
}

.navbar-dropdown-item__count{
    width: calc(14rem/16);
    height: calc(14rem/16);
    border-radius: 50%;
    background-color: var(--color-secondary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: calc(-3rem/16);
    right: calc(-3rem/16);
    font-size: calc(10rem/16);
    line-height: 1;
}
.sticker-teaser-grid-swiper .swiper-wrapper{
    @media screen and (min-width: 768px){
        justify-content: center;

    }
}

.sticker-teaser-square{
    color: #fff;
    text-align: left;
}

.sticker-teaser-square:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .7;
    background: linear-gradient(180deg,transparent,transparent 33%,#000);
}


/*
.sticker-teaser-square.sticker-teaser-square--left{
    transform-origin: center center;
    transform: rotate(-1deg);
}

.sticker-teaser-square.sticker-teaser-square--right{
    transform-origin: center center;
    transform: rotate(1deg);
}
*/

.sticker-teaser-grid--two-col{
    margin-left: auto;
    margin-right: auto;
    width: 66.66%;
}

.sticker-teaser-grid--two-col .swiper-container,
.sticker-teaser-grid--three-col .swiper-container {
    overflow: visible;
}

.sticker-teaser-grid--two-col,
.sticker-teaser-grid--three-col{
    margin-top: 2rem;

    @media screen and (min-width: 768px){
        margin-top: 4rem;
    }
}

.sticker-teaser-square__body{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(25rem/16) calc(20rem/16);
}

.sticker-teaser-square__title{
    font-size: calc(24rem/16);
    text-align: center;

    @media screen and (min-width: 768px){
        font-size: calc(28rem/16);
    }
}

/*
.sticker-teaser-square__sticker:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(74rem/16);
    height: calc(42rem/16);
    background: url(/static/img/tixo.png) no-repeat 50%;
    transform-origin: center center;
    transform: translate(-45%,-50%);
    z-index: 3;
    
    @media screen and (min-width: 768px){
        width: calc(110rem/16);
        height: calc(60rem/16);
    }

    @media screen and (max-width: 767px){
        background-size: contain;
    }
}



*/

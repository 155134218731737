.table-small th,
.table-small td,
.table-small.table-primary th,
.table-small.table-primary td{
    font-size: calc(14rem/16);
    padding: calc(4rem/16) calc(10rem/16);
}

.table-bordered {
    border-color: var(--color-bg-dark);
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 0;
}

.table-primary thead {
    border-bottom: calc(2rem/16) solid var(--color-primary);
}

.table-primary thead th{
    background-color: var(--color-primary);
    vertical-align: middle;
    padding: calc(10rem/16) calc(20rem/16) calc(10rem/16);
    line-height: calc(16/14);
    border-color: transparent;
    color: var(--color-white);
    font-size: calc(16rem/16);
}

.table-primary tbody td{
    padding: calc(10rem/16) calc(20rem/16) calc(10rem/16);
    vertical-align: middle;
    border-color: transparent;
    font-size: calc(16rem/16);
}

.table-primary tr:not(:last-child) {
    border-bottom: calc(2rem/16) solid var(--color-bg-dark);
}

.table-simple thead th{
    background-color: var(--color-grey);
}

.table td.td-vertical-align-middle{
    vertical-align: middle;
}

/* table-dashboard */
.table-primary.table-dashboard tbody td{
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px){
       vertical-align: top;
        font-size: calc(13rem/16);
    }
}
.table-primary.table-dashboard tbody th{
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.table-primary.table-dashboard thead th{
    @media screen and (max-width: 767px){
       font-size: calc(15rem/16);
    }
}

.table-primary.table-dashboard th,
.table-primary.table-dashboard td{
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) calc(7rem/16);
    }
}


.table-sticky-header{

    max-height: calc(300rem/16);
    overflow-y: auto;

    @media screen and (min-width: 768px){
       max-height: calc(680rem/16);
        overflow-y: auto;
    }
}

.table-sticky-header thead th{
    position: sticky;
    top: 0;
}
.acco-info{
    margin-top: calc(11rem/16);
    margin-left: calc(10rem/16);
    position: relative;
    z-index: 10;
}

.acco-info__btn{
    color: var(--color-primary);
    cursor: pointer;
}
.acco-info__box{
    width: calc(280rem/16);
    display: none;

    position: absolute;
    top: calc(50rem/16);
    
    background-color: #fff;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);
    padding: calc(15rem/16);
    
    @media screen and (min-width: 768px){
        left: 50%;
        margin-left: calc(-4rem/16);
        transform: translateX(-50%);
    }   
    
    @media screen and (max-width: 767px){
       //right: 0;
        left: calc(-170rem/16); /*-95*/
    }

}

.acco-info__box:before {
    content: "";
    position: absolute;
    top: 0;
    width: calc(20rem/16);
    height: calc(20rem/16);
    box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
    background-color: #fff;
    transform: rotate(45deg) translateX(-50%);

    @media screen and (min-width: 768px){
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
    }

    @media screen and (max-width: 767px){
       //right: calc(15rem/16);
        left: calc(175rem/16); /*100*/
    }
}

.acco-info__box:after {
    content: "";
    position: absolute;
    height: 1.5rem;
    left: 0;
    right: 0;
    background-color: #fff;
    top: 0;
}

.acco-info:hover .acco-info__box{
    display: block;;
}

.acco-info-item__title{
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
}

.acco-info .progress {
    height: calc(10rem / 16);
}

.acco-info-item__bar{
    position: relative;
    padding-right: calc(25rem/16);

}
.acco-info .progress__value{
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
    font-size: calc(11rem/16);
    color: var(--color-text-muted);
}

.acco-info__text{
    margin-top: calc(10rem/16);
    font-size: calc(13rem/16);
    color: var(--color-text-muted);
    line-height: calc(15/13);
}
.hero-magazin-detail{
    padding-top: calc(50rem/16);
    margin-top: calc(56rem/16);
    color: #fff;
    position: relative;
    background-color: var(--color-primary);
    
    @media screen and (min-width: 768px){
        padding-top: calc(85rem/16);
    }
}

.container-magazin-back{
    position: relative;
}

.hero-magazin-detail__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
}

/*
.hero-magazin-detail:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(150rem/16);
    z-index: 0;
}
*/


.hero-magazin-detail__body{
    position: relative;
    z-index: 3;
    @media screen and (min-width: 768px){
       margin-top: calc(50rem/16);
        padding-bottom: calc(50rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-width: 1300px){
       font-size: calc(18rem/16);
    }
}

.hero-magazin-detail__title{
    font-size: calc(60rem/16);
    line-height: 1;
    margin-bottom: calc(10rem/16);
    font-family: var(--font-amorie);
    @media screen and (min-width: 768px){
       font-size: calc(60rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(100rem/16);
    }
}

.hero-magazin-detail__subtitle{
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.hero-magazin-detail__img{
    position: relative;
    z-index: 2;
}


.hero-magazin-detail__body .small{
    font-size: calc(16rem/16);
    letter-spacing: 0;
}

/* Recipe */

.hero-magazin-detail__recipe{
    border: 2px solid #3b9b6f;
    margin-top: calc(30rem/16);
}

.hero-magazin-detail__recipe-item-wrapper{
    @media screen and (min-width: 768px){
        height: calc(60rem/16);
    }
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.hero-magazin-detail__recipe>.row>div:not(:last-child) .hero-magazin-detail__recipe-item{
    @media screen and (min-width: 768px){
        border-right: 2px solid #3b9b6f;
    }
}

.hero-magazin-detail__recipe>.row>div:first-child .hero-magazin-detail__recipe-item{
    @media screen and (max-width: 767px){
        border-right: 2px solid #3b9b6f;
    }
}

.hero-magazin-detail__recipe>.row>div:last-child .hero-magazin-detail__recipe-item{
    @media screen and (max-width: 767px){
        border-top: 2px solid #3b9b6f;
    }
}

.hero-magazin-detail__recipe-item{
    font-family: var(--font-amorie);
    line-height: 1;
    padding: calc(28rem/16) calc(15rem/16) calc(25rem/16);
}

.hero-magazin-detail__recipe-value{
    font-size: calc(60rem/16);
    line-height: 0.8;
}

.hero-magazin-detail__recipe-unit{
    font-size: calc(30rem/16);
}

.hero-magazin-detail__recipe-text{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    margin-top: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.hero-magazin-detail__recipe-difficulty .icon{
    opacity: 0.5;
    font-size: calc(40rem/16);
    margin-right: calc(3rem/16);
}


.hero-magazin-detail__recipe-difficulty.difficulty-1 .icon:first-child,
.hero-magazin-detail__recipe-difficulty.difficulty-2 .icon:first-child,
.hero-magazin-detail__recipe-difficulty.difficulty-2 .icon:nth-of-type(2),
.hero-magazin-detail__recipe-difficulty.difficulty-3 .icon{
    opacity: 1;
}
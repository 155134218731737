.accommodation-price{
    font-size: calc(30rem/16);
    line-height: calc(41/30);
}

.btn.accommodation-btn{
    font-size: calc(17rem/16);
}

.accommodation-main .btn-wishlist{
    color: var(--color-danger);
}

.accommodation-sticky-bar{
    background-color: #fff;
    opacity: 0;
    height: 0;
    transition: all 0ms ease;
}

.accommodation-main .img-gallery-grid {
    position: relative;
    padding-top: calc(1.25rem * 2);

    @media screen and (max-width: 767px) {
        padding-top: calc(1.5625rem * 2);
    }
}

.img-gallery-grid .btn-panorama {
    position: absolute;
    z-index: 11;
    top: calc(1.25rem + 10px);
    right: 1rem;

    @media screen and (min-width: 768px) {
        top: 52%;
        right: 1.25rem;
    }
}

.btn.btn-panorama {
    padding: 5px 10px;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    background-color: var(--color-white);

    @media screen and (min-width: 768px) {
        padding: 5px 20px;
        gap: 15px;
        font-size: 1.188rem;
    }
}
.btn.btn-panorama:hover {
    background-color: var(--color-primary);
}

.btn.btn-panorama span {
    font-size: 25px;
    transition: inherit;
    color: var(--color-primary);

    @media screen and (min-width: 768px){
        font-size: 45px;
    }
}
.btn.btn-panorama:hover span {
    color: var(--color-white);
}

.accommodation-sticky-bar.is-affix{
    padding: calc(12rem/16) calc(20rem/16);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto!important;
    z-index: 5;
    opacity: 1;
    height: auto;
    transition: opacity 250ms ease, height 250ms ease;
}

.accommodation-sticky-bar .accommodation-price-info{
    font-size: calc(16rem/16);
    margin-top: calc(-7rem/16)
}

.affix__container{
    @media screen and (min-width: 768px){
        padding-bottom: calc(71rem/16);
    }
}

.accomodationi-stick-bar .btn-primary {
    @media screen and (min-width: 768px) {
        margin-left: .5rem;
    }
}
.filter-grid--hero{
    position: relative;
    margin-top: calc(-400rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(-450rem/16);
    }
}

.filter-grid__title{
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(80rem/16);
        line-height: 1;
    }
}

.filter-grid__form{
    width: 100%;
    max-width: calc(1320rem/16); /* 1280px +40px padding */
    margin-right: auto;
    margin-left: auto;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
}

.filter-grid__form .btn{
    @media screen and (max-width: 1400px){
       padding: calc(8rem/16) calc(20rem/16);
    }
}
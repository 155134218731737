.alert-basic{
    background-color: #fff;
    border: 2px solid var(--color-primary);
}

.alert-notification-default {
    position: fixed;
    top: 140px;
    right: 20px;
    z-index: 2000;
    max-width: 450px;
    background-color: #fff;
    border: 2px solid var(--color-primary);
}
.author-title-block{
    text-align: center;
}

.author-title-block__img{
    width: calc(145rem/16);
    height: calc(145rem/16);
    margin: 0 auto;
    border: 10px solid #fff;
    border-radius: 50%;
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    margin-bottom: calc(25rem/16);
}

.author-title-block__img img{
    border-radius: 50%;
}

.author-title-block__title{
    font-size: calc(60rem/16);
    color: var(--color-primary);
    @media screen and (min-width: 768px){
       font-size: calc(100rem/16);
    }
}

.author-title-block__subtitle{
    font-family: var(--font-default-bold);
    margin-bottom: calc(20rem/16);
}
.datepicker{
    position: relative;
}

.datepicker:after{
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    pointer-events: none;
}

.datepicker .form-control[readonly]{
    background-color: #fff;
}

.filter-grid__form .datepicker__start{
    border-right: 1px solid #e5e5e5;
}

.filter-grid__form .datepicker__start .form-control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.filter-grid__form .datepicker__end .form-control{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
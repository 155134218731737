.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(20rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}

.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4{
    margin-bottom: calc(10rem/16);
}

.wysiwyg ol{
    padding-left: calc(19rem/16);
}

.wysiwyg ol li {
    padding-left: calc(1rem/16);
}

.wysiwyg ul{
    list-style: none;
    padding-left: 0;
}

.wysiwyg ul li{
    padding-left: calc(15rem/16);
    position: relative;
}

.wysiwyg ul li:before{
    content: '';
    position: absolute;
    top: calc(9rem/16);
    left: 0;
    width: calc(5rem/16);
    height: calc(5rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
}
.img-teaser-row .swiper-container{
    overflow: visible;
}

.img-teaser{
    position: relative;
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.img-teaser .embed-responsive-item{
    @media screen and (min-width: 768px){
       width: 100%;
    }
}

.img-teaser:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .7;
    background: linear-gradient(0deg,#000,transparent 57%);
    border-radius: calc(4rem/16);
    transition: all 250ms ease;
}


.img-teaser:hover:after{
    opacity: 0.55;
}

.img-teaser .embed-responsive{
    border-radius: 4px;
}

.img-teaser img.embed-responsive-item{
    @media screen and (min-width: 768px){
        transition: all 300ms ease;
        transform: translateX(-50%) scale(1.05);
    }

}


@media (hover: hover) {
    .img-teaser:hover img{
        transform: translateX(-50%) scale(1);
    }
}

.img-teaser__content{
    padding: calc(20rem/16);
    color: #fff;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(40rem/16);
    }
}

.img-teaser__title{
    font-family: var(--font-amorie);
    line-height: 0.95;
    font-size: calc(38rem/16);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(50rem/16);

    }
}

.img-teaser__subtitle{
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    font-family: var(--font-default-bold);
}

.img-teaser img{
    border-radius: calc(4rem/16);
}
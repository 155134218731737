
.simple-img-teaser:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .7;
    background: linear-gradient(0deg,#000,transparent 57%);
    border-radius: 4px;
    transition: all 250ms ease;
}


.simple-img-teaser:hover:after{
    opacity: 0.55;
}

.simple-img-teaser .embed-responsive{
    border-radius: 4px;
}

.simple-img-teaser img{
    border-radius: 4px;
    transition: all 300ms ease;
    transform: scale(1.05);
}

.simple-img-teaser:hover img{
    transform: scale(1);
}


.simple-img-teaser__content{
     padding: calc(20rem/16);
     color: #fff;
     position: absolute;
     bottom: 0;
     top: 0;
     left: 0;
     right: 0;
     z-index: 2;
     text-align: center;
     display: flex;
     align-items: flex-end;
     justify-content: center;
     @media screen and (min-width: 768px){
         padding: calc(35rem/16) calc(20rem/16);
     }
 }

.img-teaser-swiper .swiper-slide[class*=" col"],
.img-teaser-swiper .swiper-slide[class^=col]{
    @media screen and (max-width: 767px){
        padding: 0;
    }
}

.img-teaser-swiper .swiper-wrapper{
    @media screen and (max-width: 767px){
        flex-wrap: nowrap;
        margin: 0;
    }
}
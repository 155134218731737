.hero{
    position: relative;
}

.overlay-open .hero{
    z-index: 9999;
}

.hero:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70%;
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);

    @media screen and (min-width: 768px){
        height: 50%;
    }
}


.hero-image-wrapper{
    height: 100vh;
}

.hero-image-wrapper__img{
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.hero__image{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;

    background-size: cover;
}

.hero__image {
    background-image: var(--image-src-xs) !important;
}

@media screen and (min-width: 768px) {
    .hero__image {
        background-image: var(--image-src) !important;
    }
}


.hero__video{
    height: 100vh;
}

.hero__video video{
    position: absolute;
    right: 0;
    height: auto;
    overflow: hidden;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
}

.hero__content{
    position: absolute;
    bottom: calc(80rem/16);
    left: 0;
    right: 0;
    z-index: 50;
}

.hero__badge {
    position: absolute;
    top: 160px;
    left: 0;
    right: 0;
    z-index: 60;

    @media screen and (min-width: 768px){
        top: 225px;
    }
}

.hero__badge-item {
    position: relative;
    float: right;
    border-radius: 30px;
    border: 2px dashed var(--color-primary, #0b7746);
    background: #e5efe7;
    text-align: center;

    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 500ms;

    padding: 25px 20px 20px 20px;
    @media screen and (min-width: 768px){
        padding: 40px 30px 30px 30px;
    }
}

.hero__badge-item span,
.hero__badge-item p {
    color: var(--color-primary, #0b7746);
}

.hero__badge-item span {
    font-size: 40px;
    text-transform: uppercase;
    @media screen and (min-width: 768px) {
        font-size: 55px;
    }
}

.hero__badge-item p {
    font-weight: 600;
    font-size: 16px;
}

.hero__badge-item p:last-child {
    margin-bottom: 0;
}

.hero__badge-item-icon {
    position: absolute;
    display: flex;
    background: var(--color-primary, #0b7746);
    border-radius: 100%;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    top: -20px;

    @media screen and (min-width: 768px){
        width: 70px;
        height: 70px;
        top: -35px;
    }
}

.hero__badge-item-icon > svg {
    width: 25px;
    height: 25px;
    transition: all 500ms;
    @media screen and (min-width: 768px){
        width: 40px;
        height: 40px;
    }
}

.hero__badge-item:hover {
    border-color:var(--color-grey, #cfdfd4);
    background: #fff;
    text-decoration: none;
}

.hero__badge-item:hover .hero__badge-item-icon > svg {
    transform: rotate(-15deg);
}

.overlay-open .hero__content{
    @media screen and (max-width: 767px){
        z-index: 2001;
    }
}

.hero__text{
    color: #fff;
    text-align: center;
}

.hero__title{
    line-height: 1;
    letter-spacing: 0;
    word-spacing: 0;

    @media screen and (min-width: 768px){
       font-size: calc(80rem/16);
       line-height: 1;
    }
}

.has-no-preloaded-fonts .hero__title{

    font-family: Helvetica, sans-serif;

    font-size: 25px;
    line-height: 1.8;
    letter-spacing: -2px;
    word-spacing: 0;

    @media screen and (min-width: 768px){
        font-size: 42px;
        line-height: 1.90;
        letter-spacing: -2px;
        word-spacing: 0;
    }


    /*font-family: "Brush Script MT", cursive;

    @media screen and (min-width: 768px){
        font-size: 60px;
        line-height: 1.35;
        letter-spacing: -4px;
        word-spacing: -2px;
    }*/
}

.hero__subtitle{
    font-size: calc(22rem/16);
    line-height: calc(31/22);
    font-family: var(--font-default-bold);

}

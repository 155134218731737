.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}
.is-loading {
    position: relative;
}
.is-loading:before {
    content:'';
    display: block;
    z-index: 9;
    background-color: rgba(255,255,255,0.8);
    pointer-events:none;
    position: absolute;
    top:0;
    width:100%;
    height: 100%;
}
.flatpickr-day.is-in-range {
    color:#fff;
    background-color: var(--color-primary-light);
}
.flatpickr-day.is-arrival {
    color:#fff;
    background-color: var(--color-primary);
}
.flatpickr-day.is-departure, .flatpickr-day.selected {
    color:#fff;
    background-color: var(--color-primary);
}

.flatpickr-day.endRange, .flatpickr-day.endRange.inRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.endRange:focus, .flatpickr-day.endRange:hover, .flatpickr-day.selected, .flatpickr-day.selected.inRange, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.selected:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange, .flatpickr-day.startRange.inRange, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.startRange:focus, .flatpickr-day.startRange:hover {
    background-color: var(--color-primary);
    color: #fff;
}
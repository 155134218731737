/**
 * WARNING!
 * ---
 * This file is the base file for the Kognitiv booking widget.
 * It is included for UAB in the "main.scss" file and for external uses in the "uab-kognitiv-dsr.scss" file.
 *
 * If you change something here, keep that in mind!
 */
/* stylelint-disable */
.skd-widget-share-link,
.skd-share-link {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
}

.skd-widget.skd-widget .skd-widget-btn-primary {
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active:after,
.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover:after,
.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active,
.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover,
.skd-widget.skd-widget .skd-calendars-month td .skd-calendars-available:not(.skd-calendars-other-month),
.skd-widget.skd-widget .skd-calendars-month td .skd-calendars-only-arrival,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-legend .skd-item.skd-available,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-calendar-legend .skd-item-container .skd-date-available.skd-closed-for-arrival,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-calendar-legend .skd-item-container .skd-date-available.skd-closed-for-departure {
    background: var(--color-secondary-kognitiv) !important;
}

.skd-widget.skd-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-service-price,
.skd-widget.skd-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-add-service,
.skd-widget.skd-widget.skd-offer-list-widget .skd-service-entry .skd-service-controls .skd-remove-service,
.skd-widget.skd-widget.skd-offer-list-widget .skd-service-entry .skd-amount-tag,
.skd-widget.skd-widget.skd-offer-list-widget .skd-container-price-table .skd-rooms-ratebutton,
.skd-widget.skd-widget .skd-room-details .skd-container-rates .skd-btn-checkout,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-calendar-legend .skd-item-container .skd-only-departure,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-calendar-legend .skd-item-container .skd-only-arrival {
    background-color: var(--color-primary) !important;
}

.skd-widget.skd-widget.skd-offer-list-widget .skd-wrapper-results .skd-btn-to-offers,
.skd-widget.skd-widget .skd-price-wrapper,
.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li.skd-active:before,
.skd-widget.skd-widget.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:not(.skd-widget-disabled):hover:before,
.skd-widget.skd-widget .skd-container-policies .skd-policy h3,
.skd-widget.skd-widget.skd-offer-list-widget .skd-package .skd-package-info .skd-amount,
.skd-widget.skd-widget .skd-footer .skd-footer-seekda .skd-powered-by,
.skd-widget.skd-widget .skd-package-title .skd-occupancy-icons,
.skd-widget.skd-widget .skd-room-title .skd-occupancy-icons,
.skd-widget.skd-widget .skd-alternatives-calendar-container .skd-occupancy-icons {
    color: var(--color-primary) !important;
}

.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-date-available,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-date-available,
.skd-widget.skd-widget .skd-calendars-month td .skd-calendars-only-departure {
    background-color: var(--color-secondary-kognitiv) !important;
}

.skd-widget.skd-widget .skd-calendars-month td .skd-calendars-arrival:after,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-start-date:after,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-start-date:after {
    border-color: transparent transparent transparent var(--color-secondary-kognitiv) !important;
}

.skd-calendars-month td .skd-calendars-departure:after,
.skd-calendars-month td .skd-calendars-highlight-departure:after,
.skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-end-date:after,
.skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-end-date:after {
    border-color: var(--color-secondary-kognitiv) var(--color-secondary-kognitiv) var(--color-secondary-kognitiv) transparent !important;
}

.skd-widget[data-skd-widget="check-availability"],
.skd-widget[data-skd-widget="offer-list"] {
    max-width: 1180px;
    margin-right: auto;
    margin-left: auto;
}

.skd-widget *,
.skd-widget *:before,
.skd-widget *::before,
.skd-widget *:after,
.skd-widget *::after {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

.skd-widget img[src*="skd_spinner.gif"] {
    filter: hue-rotate(90deg);
}

.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-date-available:hover,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-date-available:hover,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-start-date,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-start-date,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-selected,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-selected:after,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-selected,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-selected:after,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-alternative-calendar .skd-end-date,
.skd-widget.skd-widget .skd-alternatives-offer .skd-alternative-offers-cal-container .skd-calendar-legend .skd-end-date {
    background-color: var(--color-primary) !important;
    color: #fff !important;
}

.skd-widget.skd-widget .skd-alternative-calendar *[class*="user-preference"] {
    border-color: rgba(0, 0, 0, 0.4) !important;
}

.skd-widget.skd-widget .skd-widget-disabled > a,
.skd-widget.skd-widget .skd-active.skd-pointer > a {
    display: block !important;
}

.skd-widget.skd-widget .skd-room-details .skd-container-rates .skd-btn-checkout {
    line-height: 40px !important;
    border-radius: 4px !important;
}

.skd-widget.skd-widget .skd-slidesjs-pagination {
    bottom: -28px !important;
}

.skd-widget.skd-widget .skd-slidesjs-next:hover,
.skd-widget.skd-widget .skd-slidesjs-previous:hover {
    color: var(--color-primary) !important;
}

.skd-widget.skd-widget .skd-widget-container-xs.skd-offer-list-widget .skd-rooms-slides {
    margin-bottom: 50px !important;
}

.skd-widget.skd-widget .skd-widget .skd-widget-tooltip-inner {
    font-weight: 400 !important;
}

.skd-widget.skd-widget .skd-share-row {
    font-style: normal !important;
}

.skd-widget.skd-widget .skd-share-row a,
.skd-widget.skd-widget .skd-share-row a:hover {
    color: var(--color-secondary-kognitiv) !important;
}

@media screen and (min-width: 841px) {
    .skd-widget .skd-share-row {
        margin-top: 20px !important;
    }
}

.skd-widget.skd-widget .skd-button-edit a,
.skd-widget.skd-widget .skd-button-edit a:hover {
    color: var(--color-primary) !important;
}

.skd-widget.skd-widget .skd-widget .skd-room-details .skd-container-rates .skd-btn-checkout {
    font-size: inherit !important;
}

.skd-widget.skd-widget .skd-btn-to-offers {
    font-style: normal !important;
}

.skd-widget.skd-widget .skd-widget-container-xs ul.skd-rates-accordion > li .skd-rate-description {
    font-size: 14px !important;
}

.skd-widget.skd-widget .skd-widget-alert-info {
    background-color: #faf4ea !important;
}

.skd-widget.skd-widget h2.skd-fancy-header,
.skd-widget.skd-widget h5.skd-fancy-header {
    font-style: normal !important;
    font-weight: 500 !important;
}

.skd-widget.skd-widget h2.skd-fancy-header:before,
.skd-widget.skd-widget h5.skd-fancy-header:before {
    right: 1em !important;
}

.skd-widget.skd-widget h2.skd-fancy-header:after,
.skd-widget.skd-widget h5.skd-fancy-header:after {
    left: 1em !important;
}

.skd-widget.skd-widget .skd-circle.skd-label {
    padding-top: 2px !important;
}

.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight.skd-calendars-available,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-available,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-today,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-weekend,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-departure,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight-departure {
    background-color: var(--color-primary) !important;
}

.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight.skd-calendars-available > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-available > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-today > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-selected.skd-calendars-weekend > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-departure > span,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight-departure > span {
    color: #fff !important;
}

.skd-check-availability-widget .skd-legend .item.selected,
.skd-check-availability-widget .skd-legend .item.skd-selected,
.skd-check-availability-widget .skd-legend .skd-item.skd-selected {
    background: var(--color-primary) !important;
}

.skd-check-availability-widget .skd-calendars-month td .skd-was-selected-before {
    border-color: transparent !important;
}

.skd-check-availability-widget .skd-calendars-month td .skd-calendars-arrival:after {
    left: -1px !important;
}

.skd-check-availability-widget .skd-calendars-month td .skd-calendars-departure:after,
.skd-check-availability-widget .skd-calendars-month td .skd-calendars-highlight-departure:after {
    top: -1px !important;
}

.skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-room-box-container .skd-room-box .skd-room-nr .skd-circle {
    background-color: var(--color-secondary-kognitiv) !important;
}

.skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-add-room,
.skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-room-box-container .skd-room-box .skd-room-overview .skd-invitation,
.skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-add-room .skd-plus-sign {
    color: var(--color-secondary-kognitiv) !important;
}

.skd-check-availability-widget .skd-widget-form-control.skd-children,
.skd-check-availability-widget .skd-widget-form-control.skd-adults {
    padding-top: 2px !important;
}

.skd-check-availability-widget .skd-calendar .skd-legend {
    font-size: 14px !important;
}

.skd-check-availability-widget .skd-check-availability-widget .skd-legend .item-container,
.skd-check-availability-widget .skd-check-availability-widget .skd-legend .skd-item-container {
    line-height: 25px !important;
}

.skd-offer-list-widget .container-progress ul.skd-breadcrumbs li:before {
    padding-top: 2px !important;
    line-height: 17px!important;
}

.skd-offer-list-widget h3.skd-room-title,
.skd-offer-list-widget .skd-room-title > h3 {
    color: var(--color-primary) !important;
}

.skd-offer-list-widget .skd-room-title > h3 {
    margin-right: 10px !important;
}

@media screen and (max-width: 840px) {
    .skd-offer-list-widget .skd-room-title > h3 {
        margin-left: 10px !important;
        margin-bottom: 10px !important;
    }
}

.skd-offer-list-widget .skd-room-title {
    padding-bottom: 0 !important;
}

.skd-offer-list-widget .skd-room-info h4 {
    margin-top: -5px !important;
}

.skd-offer-list-widget .skd-room-description {
    padding: 0 !important;
}

.skd-offer-list-widget .skd-rooms-fullimage-price {
    color: var(--color-secondary-kognitiv) !important;
}

.skd-offer-list-widget .skd-room-info .skd-single-offer-icons {
    margin-bottom: 10px !important;
}

.skd-offer-list-widget div.skd-wrapper-results.skd-offer-page > div.skd-widget-results-pane > div > div.container-dayrates.skd-widget-clearfix > div.skd-room-container.skd-single-rate-container.skd-widget-row > div:nth-child(2) > div.skd-widget-col-sm-3.skd-container-price-table.skd-single-rate.skd-room-rate-offer > div.skd-rooms-ratebutton.skd-single-rate.skd-pointer {
    display: inline-block !important;
}

.skd-offer-list-widget .skd-precheckout .skd-offer-details-payment-box {
    background-color: #faf4ea !important;
}

.skd-offer-list-widget .skd-priceButton {
    color: var(--color-primary) !important;
}

.skd-offer-list-widget .skd-container-price-table .skd-rooms-ratebutton.skd-single-rate {
    width: auto !important;
    height: 40px !important;
    line-height: 44px !important;
    background-image: none !important;
    padding: 0 20px !important;
}

.skd-offer-list-widget .skd-container-price-table .skd-rooms-ratebutton.skd-single-rate > .skd-priceButton {
    position: relative !important;
}

.skd-offer-list-widget .skd-container-price-table .skd-rooms-ratebutton.skd-single-rate::before {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: var(--color-primary) !important;
    border-radius: 4px !important;
}

.skd-offer-list-widget .skd-wrapper-results .skd-btn-to-offers {
    font-style: normal !important;
}

/* stylelint-enable */
/* stylelint-disable */
.skd-widget.skd-widget *:not(i):not(.fa) {
    font-family: var(--font-default);
}

.skd-widget.skd-widget .skd-widget-disabled > a,
.skd-widget.skd-widget .skd-active.skd-pointer > a {
    font-size: 14px !important;
}

.skd-widget.skd-widget .skd-rate-description {
    font-size: 14px !important;
}

.skd-widget.skd-widget .skd-room-title .skd-occupancy-icons {
    font-size: 1.3em !important;
}

.skd-offer-list-widget h3.skd-room-title,
.skd-offer-list-widget .skd-room-title > h3 {
    font-family: var(--font-default-bold) !important;
    font-size: 28px !important;
}

.skd-offer-list-widget .skd-container-price-table .skd-rooms-ratebutton.skd-single-rate > .skd-priceButton {
    font-family: var(--font-default-bold) !important;
}

.skd-offer-list-widget .skd-room-description {
    font-size: 14px !important;
}

.skd-offer-list-widget .skd-container-description .skd-room-text,
.skd-offer-list-widget .skd-wrapper-results .skd-room-amenities ul.skd-amenities-list {
    font-size: 14px !important;
}

.skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-occupancy .skd-room-box-container .skd-room-box .skd-room-nr .skd-label {
    line-height: 20px !important;
}

.skd-check-availability-widget label {
    font-size: 14px !important;
}

body .skd-check-availability-widget .skd-contentContainer .skd-search-box .skd-widget-form-inline .skd-additional-controls{
    font-size: calc(15rem/16)!important;
}

/* stylelint-enable */


.wysiwyg-with-images.slide{
    padding-top: calc(150rem/16);
    @media screen and (max-width: 767px){
       padding-top: calc(60rem/16);
    }
}

.wysiwyg-with-images img{
    border-radius: 4px;
}

.wysiwyg-with-images__img-big{
    border-radius: 4px;
    transform-origin: left top;
    transform: rotate(-4deg);
    width: 95%;

}

.wysiwyg-with-images__img-small{
    border-radius: 4px;
    transform-origin: left top;
    transform: rotate(4deg);
    width: 54%;
    float: right;
    margin-top: calc(-80rem/16);
    margin-right: calc(-15rem/16);
    
    @media screen and (min-width: 768px){
        margin-top: calc(-160rem/16);
        margin-right: calc(-35rem/16);
    }

}
.wysiwyg-with-images__img-small.is-portrait--small{
    width: 40%;
    margin-top: calc(-128rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(-195rem/16);
    }
}
.wysiwyg-with-images__img-big.is-portrait--big{
    width: 60%;
}

.wysiwyg-with-images .lightbox-img__btn{
    right: auto;
    left: calc(-18rem/16);
    transform-origin: bottom left;

}

.wysiwyg-with-images .lightbox-img__btn .icon{
    left: calc(3rem/16);
}
.notification-box__title{
    padding-left: calc(30rem/16);
    position: relative;
}

.notification-box__title-icon{
    position: absolute;
    left: 0;
    top: calc(4rem/16);
}

.notification-box__btn-wrapper{
    margin-left: calc(30rem/16);
}
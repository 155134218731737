.list-downloads .list-inline-item:not(:last-child){
    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }
}

.list-downloads__item{
    font-family: var(--font-default-bold);
    font-size: calc(18rem/16);
    display: flex;
    align-items: center;
    margin-bottom: calc(15rem/16);
}

.list-downloads__item:hover .list-downloads__text{
    text-decoration: underline;
}

.download__info{
    color: var(--color-text-muted);
    font-size: calc(12rem/16);
    margin-left: calc(5rem/16);
    position: relative;
    top: calc(3rem/16);
}

.download__file-size{
    white-space: nowrap;
}

.list-downloads__text{
    @media screen and (max-width: 767px){
       text-align: left;
    }
}
.badge{
    padding: calc(8rem/16) calc(10rem/16);
}
.acco-badge .badge{
    @media screen and (min-width: 768px){
        padding: calc(12rem/16) calc(17rem/16);
        font-size: calc(18rem/16);
    }
}

.accommodation-teaser .acco-badge .badge{
    @media screen and (min-width: 768px){
       font-size: calc(13rem/16);
        padding: calc(7rem/16) calc(8rem/16);
    }
}
.form-control{
    font-family: var(--font-default-bold);
    padding: calc(20rem/16) calc(15rem/16) calc(8rem/16);
    border: 1px solid var(--color-bg-dark);
    color: var(--color-dark);
    @media screen and (min-width: 768px){
       height: calc(60rem/16);
        padding: calc(20rem/16) calc(20rem/16) calc(8rem/16);
    }
}

.form-control--no-floating-label{
    padding: calc(8rem/16) calc(15rem/16) calc(8rem/16);

    @media screen and (min-width: 768px){
        padding: calc(8rem/16) calc(20rem/16) calc(8rem/16);
    }
}

.form-group--xl .form-control{
    @media screen and (min-width: 768px){
        height: calc(89rem/16);
        font-size: calc(16rem/16);
        padding: calc(34rem/16) calc(12rem/16) calc(8rem/16);
    }

    @media screen and (min-width: 1400px){
        font-size: calc(18rem/16);
        padding: calc(34rem/16) calc(25rem/16) calc(8rem/16);
    }
}

.form-control.disable{
    pointer-events: none;
}

.input-group .btn-icon{
    width: calc(50rem/16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(18rem/16);
}

.input-group .form-control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-dropdown);
    color: var(--color-primary);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(7rem / 16);
    top: 50%;
    right: calc(20rem / 16);
    transform: translateY(-50%);
    z-index: 1;
}


.select--simple .form-control{
    background-color: transparent;
    color: var(--color-primary);
    padding: 0 calc(30rem/16) 0 0;
    border: none;
    font-size: calc(19rem/16);
}

.select.select--simple:before{
    right: calc(10rem/16);
}

.form-group--xl.select:before{
    top: auto;
    transform: none;
    bottom: calc(25rem/16);
    right: calc(15rem/16);
}

.input-group-custom .form-control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-custom .btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color-dark);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--color-dark);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--color-dark);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--color-dark);
}

.input-group .custom-select:not(:last-child),
.input-group .form-control:not(:last-child){
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.form-small{
    font-size: calc(15rem/16);
}

.form-group .toggle-password {
    border: 0;
    background: none;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -15px;
    width: 40px;
    height: 30px;
    font-size: 24px;
    outline: 0;
    color: var(--color-grey);
}

.form-group .toggle-password.is-enabled {
    color: var(--color-dark-grey);
}
.swiper-pagination-bullets{
    width: 100%;
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
    }
}

.swiper-pagination-bullet{
    margin: 0 calc(5rem/16);
    border: 1px solid var(--color-grey);
    background-color: transparent;
    opacity: 1;
    transition: all 150ms ease;
    width: calc(15rem/16);
    height: calc(15rem/16);

    @media screen and (min-width: 768px){
        width: calc(20rem/16);
        height: calc(20rem/16);
        border: 2px solid var(--color-grey);
    }
}

.swiper-pagination-bullet.swiper-pagination-bullet-active,
.swiper-pagination-bullet:hover{
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.swiper-same-height .swiper-slide{
    height: auto;
}

.swiper-pagination{
    left: 0;
    right: 0;
}


.swiper-navigation{
    z-index: 11;
    display: flex;
    align-items: center;
    position: relative;
}

.swiper-button{
    color: var(--color-primary);
    cursor: pointer;
    z-index: 12;
    position: relative;
}

.swiper-button--prev {
    margin-right: calc(15rem/16);
}
.swiper-button--next {
    @media screen and (min-width: 768px) {
        margin-right: calc(15rem/16);
    }
}

.swiper-button:focus{
    outline: 0;
}

.swiper-button-disabled{
    opacity: 0.5;
}

.image-swiper .swiper-navigation,
.acco-teaser-hori-swiper .swiper-navigation{
    margin-top: calc(15rem/16);
    justify-content: flex-end;
}

.image-swiper .swiper-pagination-bullets,
.acco-teaser-hori-swiper .swiper-pagination-bullets{
    margin-top: 0;
}


@media screen and (min-width: 768px){
    .acco-teaser-hori-swiper .swiper-slide {
        max-height: 277px;
        max-width: 640px;
        padding: 0 calc(10rem/16);

    }

    .image-swiper .swiper-slide {
        max-height: 466px;
        max-width: 640px;
        padding: 0 calc(10rem/16);
    }

    .img-teaser-swiper .swiper-slide{
        max-height: 507px;
        max-width: 547px;
        padding: 0 calc(20rem/16);
    }
}


.swiper-container.is-init .swiper-slide{
    max-height: none;
    max-width: none;
    padding: 0;
}

.swiper-container.is-init .swiper-slide:last-child {
    margin-right: 0!important;
}
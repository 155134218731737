.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #fff;
}
.flatpickr-calendar.arrowTop:before{
    border-bottom-color: #d1d1d1;
}
.flatpickr-calendar:before{
    border-width: 10px;
}
.flatpickr-calendar:after{
    border-width: 9px;
}
.flatpickr-calendar{
    margin-top: calc(-1rem/16);
}

.dropdown-menu{
    border-radius: calc(3rem/16);
    border: 0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    padding: calc(25rem/16);
}

.dropdown--profil .dropdown-toggle:focus{
    outline: none;
    border: none;
}

.dropdown--profil .dropdown-toggle:after{
    display: none;
}

.dropdown--profil .dropdown-menu{
    transform: translateX(-50%);
    left: 50%;
    margin-top: calc(20rem/16);
    padding: calc(25rem/16) 0;

    @media screen and (min-width: 768px){
        min-width: calc(230rem/16);
    }
}

.dropdown--arrow .dropdown-menu:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;

    @media screen and (min-width: 768px){
        left: 63.5%;
    }

    @media screen and (min-width: 1550px){
        left: 50%;
    }
}
.dropdown--arrow .dropdown-menu:after {
    content: '';
    position: absolute;
    height: calc(24rem/16);
    left: 0;
    right: 0;
    background-color: #fff;
    top: 0;
}
.list-check{
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.list-check>li{
    position: relative;
    padding-left: calc(20rem/16);
    margin-bottom: calc(10rem/16);
    
    @media screen and (min-width: 768px){
        padding-left: calc(32rem/16);
        margin-bottom: calc(15rem/16);
    }
}

.list-check>li:before{
    content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    left: 0;
    top: calc(3rem/16);
    color: var(--color-primary);
    font-size: calc(12rem/16);
    
    @media screen and (min-width: 768px){
        top: calc(2rem/16);
        color: var(--color-primary);
        font-size: calc(17rem/16);
    }
}

.tooltip-btn{
    width: calc(17rem/16);
    height: calc(17rem/16);
    background-color: var(--color-secondary);
    color: #fff;
    border-radius: 50%;
    border: none;
    padding: 0;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.popover-header .close{
    display: block;
    margin-top: calc(-5rem/16);
    color: var(--color-primary);
    opacity: 1;
}

.popover-body .close{
    position: absolute;
    top: calc(6rem/16);
    right: calc(6rem/16);
    font-size: calc(19rem/16);
    padding: 0;
    line-height: 0.5;
    display: block;
}

.tooltip a{
    color: var(--color-primary);
}
.sticker-teaser{
    color: #fff;
    text-align: left;
}

.sticker-teaser:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .7;
    background: linear-gradient(180deg,transparent,transparent 33%,#000);
}


.sticker-teaser.sticker-teaser--first,
.sticker-teaser.sticker-teaser--third{
    transform-origin: bottom right;
    transform: rotate(5deg);
}

.sticker-teaser.sticker-teaser--second{
    transform-origin: bottom left;
    transform: rotate(-5deg);
}

.sticker-teaser-row{
    margin-top: calc(50rem/16);
    @media screen and (min-width: 768px){
       margin-top: calc(80rem/16);
    }
}

.sticker-teaser-row .swiper-container {
    overflow: visible;
}

    /*
    .sticker-teaser-row .swiper-slide{
        padding: calc(40rem/16);
    }
    */

.sticker-teaser-row .swiper-slide:nth-of-type(2){
    margin-right: calc(-30rem/16)!important; /* because of swiper inline style */
    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
        margin-right: calc(-40rem/16)!important; /* because of swiper inline style */
    }
}

.sticker-teaser__body{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(40rem/16) calc(20rem/16);
}

.sticker-teaser__toptitle{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(1rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }
}

.sticker-teaser__title{
    font-size: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(28rem/16);
    }
}

.sticker-teaser__sticker:before,
.sticker-teaser__sticker:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(74rem/16);
    height: calc(42rem/16);
    background: url(/static/img/tixo.png) no-repeat 50%;
    transform-origin: center center;
    transform: translate(-45%,-45%);
    z-index: 3;
    
    @media screen and (min-width: 768px){
        width: calc(110rem/16);
        height: calc(60rem/16);
    }

    @media screen and (max-width: 767px){
        background-size: contain;
    }
}

.sticker-teaser--first .sticker-teaser__sticker:before{
    transform: translate(-50%,-50%) rotate(-45deg);
    margin-top: calc(10rem/16);
    margin-left: calc(10rem/16);
}

.sticker-teaser--first .sticker-teaser__sticker:after{
    top: 100%;
    transform: translate(-50%,-50%) rotate(45deg);
    margin-top: calc(-10rem/16);
    margin-left: calc(10rem/16);
}

.sticker-teaser--second .sticker-teaser__sticker:before{
    left: 50%;
}

.sticker-teaser--second .sticker-teaser__sticker:after{
    top: 100%;
    left: 50%;
}


.sticker-teaser--third .sticker-teaser__sticker:before{
    left: 100%;
    transform: translate(-50%,-50%) rotate(45deg);
    margin-top: 10px;
    margin-left: -10px;
}

.sticker-teaser--third .sticker-teaser__sticker:after{
    top: 100%;
    left: 100%;
    transform: translate(-50%,-50%) rotate(-45deg);
    margin-top: calc(-10rem/16);
    margin-left: calc(-10rem/16);
}

.img-content-teaser{
    background-color: #fff;
}

.img-content-teaser__body{
    padding: calc(25rem/16);
}
.img-content-teaser__title{
    text-transform: uppercase;
    font-family: var(--font-amorie);
}

.is-uab .img-content-teaser__title{
    color: var(--color-uab);
}

.is-pzg .img-content-teaser__title{
    color: var(--color-pzg);
}
.navbar-subnav{
    @media screen and (min-width: 768px){
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: -1;

        padding: calc(70rem/16) 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms ease, visibility 200ms ease;
        transition-delay: 100ms;
        pointer-events: none;
    }
    
    @media screen and (max-width: 767px){
        max-height: 0;
        overflow: hidden;
        padding-top: calc(18rem/16);

        transition: max-height .25s ease-in-out;
    }
}

.navbar-subnav--map{
    @media screen and (min-width: 768px) and (max-height: 720px){
        padding: calc(40rem/16) 0;
    }
}

.nav-item-main.is-open .navbar-subnav{
    @media screen and (min-width: 768px){
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
        transition: opacity 600ms ease, visibility 100ms ease;
        transition-delay: 100ms;
    }
    
    @media screen and (max-width: 767px){
       max-height: none;
    }
}


.navbar-subnav:after{
    @media screen and (min-width: 768px){
        content: '';
        background-color: #fff;
        opacity: 0.95;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.navbar-white:not(.is-affix) .navbar-subnav:after{
    @media screen and (min-width: 768px){
        background-color: var(--color-bg);
    }
}


.navbar-white .navbar-subnav:before {
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: calc(74rem/16);
        transform: translateY(-100%);
        background-color: #fff;
        opacity: 1;
        z-index: -1;
    }

}


.navbar-white .navbar-subnav:before{
    @media screen and (min-width: 768px){
        background-color: var(--color-bg);
    }
}

.is-affix .is-open .navbar-subnav:before{
    @media screen and (min-width: 768px){
       opacity: 0.1;
    }
}


.navbar-subnav .container{
    position: relative;
    z-index: 2;
}



.navbar-subnav__title{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    margin-bottom: calc(10rem/16);
    color: var(--color-primary);
    display: block;

    @media screen and (min-width: 768px){
        display: inline-block;
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 1360px){
        font-size: calc(22rem/16);
    }
    
    @media screen and (max-width: 767px){
       position: relative;
        padding: calc(5rem/16) 0 calc(3rem/16);
    }
}

.navbar-subnav__title:hover{
    color: var(--color-primary-dark);
}

/*.navbar-subnav .col-md-auto{
    @media screen and (min-width: 768px){
       width: 20%;
    }
    @media screen and (min-width: 1600px){
        width: 15%;
    }
}*/

.navbar-subnav__col{
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        width: calc(170rem/16);
    }

    @media screen and (min-width: 992px){
        width: calc(180rem/16);
    }

    @media screen and (min-width: 1360px){
        width: calc(220rem/16);        
        font-size: calc(18rem/16);

    }

    @media screen and (min-width: 1650px){
        width: calc(280rem/16);
    }
}

.col-md-3 .navbar-subnav__col {
    @media screen and (min-width: 768px){
        width: auto;
    }
}

.navbar-subnav__col-item{
    @media screen and (max-width: 767px){
       font-size: calc(18rem/16);
    }
}
.navbar-subnav__col-item:not(:last-child){
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(12rem/16);
    }
    @media screen and (min-width: 1360px){
        margin-bottom: calc(17rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-height: 720px){
       margin-bottom: calc(6rem/16);
    }
}

.navbar-subnav__col-item a:hover{
    color: var(--color-primary);
}


.navbar-subnav__toggler{
    color: var(--color-primary);
    position: absolute;
    right: calc(-5rem/16);
    top: calc(3rem/16);
    font-size: calc(9rem/16);
    padding: calc(10rem/16);
    z-index: 50;
}

.navbar-subnav__subnav{
    @media screen and (max-width: 767px){
        max-height: 0;
        overflow: hidden;
        transition: all .25s ease;
        padding-left: calc(20rem/16);
    }
}

.navbar-subnav__col.is-open .navbar-subnav__subnav{
    @media screen and (max-width: 767px){
        max-height: 100vh;
        padding-bottom: calc(15rem/16);
        padding-top: calc(10rem/16);
    }
}

.navbar-subnav__col.is-open .navbar-subnav__toggler:before{
    transform: rotate(180deg);
    display: inline-block;
}


.navbar-subnav__regions{
    @media screen and (min-width: 768px){
        text-align: center;
    }
}

.navbar-subnav__regions svg{
    @media screen and (min-width: 768px){
       margin-left: calc(-150rem/16);
        height: 50vh;
    }
}
.acco-info-teaser{
    @media screen and (min-width: 768px){
        width: calc(350rem/16);
    }
}

.acco-info-teaser__body{
    padding: 0 calc(10rem/16);
    text-align: center;
}

.acco-info-teaser__title{
    font-size: calc(35rem/16);
    line-height: 0.9;
    font-family: var(--font-amorie);
    color: var(--color-primary);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px){
       font-size: calc(40rem/16);
    }
}


.acco-info-teaser__img{
    @media screen and (min-width: 768px){
       width: calc(150rem/16);
    }
}
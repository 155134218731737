.back-bar{
    display: inline-flex;
    align-items: center;
    padding: calc(5rem/16) 0;
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(25rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px){
        margin-bottom: calc(40rem/16);
    }
}

.back-bar .icon{
    font-size: calc(11rem/16);
    margin-right: calc(7rem/16);
}
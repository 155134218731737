.quote-area__icon{
    color: var(--color-primary);
    font-size: calc(50rem/16);
    text-align: center;
    text-shadow: 4px 4px rgba(11, 119, 70, 0.1);
}


.quote-area__quote{
    color: var(--color-primary);
    font-size: calc(36rem/16);
    line-height: 1.2;
    font-family: var(--font-amorie);
    text-align: center;
    margin: 0 0 calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(60rem/16);
        margin: 0 calc(60rem/16) calc(20rem/16);
    }
}

.quote-area__author-name{
    font-family: var(--font-default-bold);
    font-size: calc(18rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.quote-area__author-description{
    text-transform: uppercase;
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}

.quote-area__author-img{
    width: calc(100rem/16);
    height: calc(100rem/16);
    border-radius: 50%;

    @media screen and (min-width: 768px){
        width: calc(120rem/16);
        height: calc(120rem/16);
    }

    @media screen and (max-width: 767px){
       margin: 0 auto;
    }
}